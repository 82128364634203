import { authTokenCookie } from '../utils/CookieMaker';
// import { Environment } from '../utils/Environment';

export const getNoCacheHeaders = () => {
  let { token, idToken } = authTokenCookie();
  // let tempAuthToken = token ? token.replace("%7B%22accessToken%22%3A%22", "") : token;
  // tempAuthToken = tempAuthToken ? tempAuthToken.replace("%22%7D", "") : tempAuthToken;
  // let tempIdToken = idToken ? idToken.replace("%7B%22idToken%22%3A%22", "") : idToken;
  // tempIdToken = tempIdToken ? tempIdToken.replace("%22%7D", "") : tempIdToken;
  return {
    "Cache-control": "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
    Pragma: "no-cache",
    Expires: "0",
   // "Authorization": `Bearer ${token}`,
   // "idToken": idToken
    //  applicationId: "1",
    //  userId: userId,
      "Ocp-Apim-Subscription-Key": ``,
      "svwpToken":`ent-abs-auth=${token};ent-abs-itkn=${idToken}`
     // Set-Cookie:Cookie=`ent-abs-auth=${token};ent-abs-itkn=${idToken}`;HttpOnly,
  };
};
