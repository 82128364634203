import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store/index';
import { Provider } from 'react-redux';
import AccessDetailsProvider from "providers/AccessDetailsProvider";
import UserAuthorization from './common/Authorization';
import { ErrorBoundary } from './common/Error404/ErrorBoundary'
import './fonts/NunitoSans-SemiBold.ttf';
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom';
const persistor = persistStore(store)
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AccessDetailsProvider>
            <UserAuthorization>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </UserAuthorization>
          </AccessDetailsProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
