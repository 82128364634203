import {SERVICE_INPROGRESS } from "../loader/LoaderAction";

const initialState = {
   isLoading: false,
 };
 const Loader = (state = initialState, action) => {
    switch (action.type) {
       case SERVICE_INPROGRESS:
          return Object.assign({}, state, {
            isLoading: action.value
          })
       default:
          return state;
    }
 }
 export default Loader;