import {
    SET_DEFAULT_ORDER_STATUS,    

} from './searchResultAction';
const initialState = {
    defaultOrderType: '',
    

};
const searchResult = (state = initialState, action) => {
    switch (action.type) {
        case SET_DEFAULT_ORDER_STATUS:
            return Object.assign({}, state, {
                defaultOrderType: action.value
            })
        
        default:
            return state;
    }
}
export default searchResult;