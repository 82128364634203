import { SET_COMMENT, SET_COMMENT_ERROR,SET_COMMENT_POSTED } from "./commentAction";
const initialState = {
  comment: "",
  commentError: "",
  commentStatus:0,
};
const comment = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMENT:
      return Object.assign({}, state, {
        comment: action.value,
      });
    case SET_COMMENT_ERROR:
      return Object.assign({}, state, {
        commentError: action.value,
      });
    case SET_COMMENT_POSTED:
      return Object.assign({}, state, {
        commentStatus: action.value,
      });

    default:
      return state;
  }
};
export default comment;
