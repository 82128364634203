export const SET_COMMENT = `SET_COMMENT`
export const SET_COMMENT_ERROR = `SET_COMMENT_ERROR`
export const SET_COMMENT_POSTED = `SET_COMMENT_POSTED`


export const setComment = (data) => ({
    type: SET_COMMENT,
    value: data
})
export const setCommentError = (data) => ({
    type: SET_COMMENT_ERROR,
    value: data
})
export const setCommentPosted = (data) => ({
    type: SET_COMMENT_POSTED,
    value: data
})

