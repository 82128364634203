export const SET_ORDER_NUMBER = `SET_ORDER_NUMBER`
export const SET_ORDER_NUMBER_ERROR = `SET_ORDER_NUMBER_ERROR`


export const setOrderNumber = (data) => ({
    type: SET_ORDER_NUMBER,
    value: data
})
export const setOrderNumberError = (data) => ({
    type: SET_ORDER_NUMBER_ERROR,
    value: data
})
