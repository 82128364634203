// import { userRoleVal } from "utils/constants";

export const GET_ROLE = `GET_ROLE`;
export const SET_ROLE = `SET_ROLE`;
export const GET_DETAILS = `GET_DETAILS`;

export const GET_SEARCHRESULT = `GET_SEARCHRESULT`;
export const GET_CUSTOMER_COMMENTS = `GET_CUSTOMER_COMMENTS`;
export const GET_EXPORTTOEXCEL = `GET_EXPORTTOEXCEL`;
export const GET_APIMKEY = `GET_APIMKEY`;
export const GET_USERSFKEYMAP = `GET_USERSFKEYMAP`;
export const GET_ISSUES = `GET_ISSUES`;
export const GET_ACCESSDETAILS = `GET_ACCESSDETAILS`;
export const GET_ORDER_STATUS = `GET_ORDER_STATUS`;
export const GET_COMPANY = `GET_COMPANY`;
export const GET_CONTACT = `GET_CONTACT`;
export const GET_STORENUMBER = `GET_STORENUMBER`;
export const GET_ORDER_TYPE = `GET_ORDER_TYPE`;
export const GET_PRODUCT_SELECTION = `GET_PRODUCT_SELECTION`;
export const GET_DIVISION_SELECTION = `GET_DIVISION_SELECTION`;

export const SET_DETAILS = `SET_DETAILS`;
export const SET_SEARCHRESULT = `SET_SEARCHRESULT`;
export const SET_CUSTOMER_COMMENTS = `SET_CUSTOMER_COMMENTS`;
export const SET_EXPORTTOEXCEL = `SET_EXPORTTOEXCEL`;
export const SET_APIMKEY = `SET_APIMKEY`;
export const SET_USERSFKEYMAP = `SET_USERSFKEYMAP`;
export const SET_ACCESSDETAILS = `SET_ACCESSDETAILS`;
export const SET_ORDER_STATUS = `SET_ORDER_STATUS`;
export const SET_COMPANY = `SET_COMPANY`;
export const SET_CONTACT = `SET_CONTACT`;
export const SET_STORENUMBER = `SET_STORENUMBER`;
export const SET_ORDER_TYPE = `SET_ORDER_TYPE`;
export const SET_PRODUCT_SELECTION = `SET_PRODUCT_SELECTION`;
export const SET_DIVISION_SELECTION = `SET_DIVISION_SELECTION`;
export const SET_DIVISION = `SET_DIVISION`;
export const SET_GIFT_CARD = `SET_GIFT_CARD`;

export const GET_ORDER_DETAIL = `GET_ORDER_DETAIL`;
export const SET_ORDER_DETAIL = `SET_ORDER_DETAIL`;
export const GET_ORDER_ADITIONALINFO = `GET_ORDER_ADITIONALINFO`;
export const SET_ORDER_ADITIONALINFO = `SET_ORDER_ADITIONALINFO`;
export const SET_SELECTED_PRODUCT_ITEM = `SET_SELECTED_PRODUCT_ITEM`;

export const GET_FUllFillMENT = `GET_FUllFillMENT`;
export const SET_FUllFillMENT = `SET_FUllFillMENT`;
export const SET_FUllFillMENT_COMPLETED = `SET_FUllFillMENT_COMPLETED`;
export const SET_FUllFillMENT_STATUS = `SET_FUllFillMENT_STATUS`;
export const SET_EXPANDED_ORDER = `SET_EXPANDED_ORDER`;
export const SET_ORDER_LOCKED_BY = `SET_ORDER_LOCKED_BY`;
export const GET_CARD = `GET_CARD`;
export const SET_PAGE_LOAD = `SET_PAGE_LOAD`;
export const GET_UNLOCK_ORDER = `GET_UNLOCK_ORDER`;
export const SET_UNLOCK_ORDER = `SET_UNLOCK_ORDER`;
export const SET_SUCCESS_MESSAGE = `SET_SUCCESS_MESSAGE`;
export const SET_SUCCESS_MESSAGE_TYPE = `SET_SUCCESS_MESSAGE_TYPE`;

export const SET_PRINTPACKSLIP = `SET_PRINTPACKSLIP`;
export const SET_PRINT_INVOICE = `SET_PRINT_INVOICE`;
export const SET_TEXTAREA_VALUE = `SET_TEXTAREA_VALUE`;
export const SET_STEP3_STATUS = `SET_STEP3_STATUS`;
export const SET_SAVE_BTN_STATUS = `SET_SAVE_BTN_STATUS`;
export const SET_TRACK_BTN_STATUS = `SET_TRACK_BTN_STATUS`;
export const SET_TRACK_TXT_BOX_STATUS = `SET_TRACK_TXT_BOX_STATUS`;
export const SET_TRACK_DATA_RECEIVED_FROM_SAVE = `SET_TRACK_DATA_RECEIVED_FROM_SAVE`;
export const GET_FILEATTACHED = "GET_FILEATTACHED";
export const SET_FILEATTACHED = "SET_FILEATTACHED";
export const SET_FILES_TO_DISPLAY = "SET_FILES_TO_DISPLAY";
export const DOWNLOAD_FILEATTACHED = "DOWNLOAD_FILEATTACHED";
export const DELETE_FILEATTACHED = "DELETE_FILEATTACHED";
export const SET_FILEMSG = "SET_FILEMSG";
export const SET_PRINT_SLIP_MSG = "SET_PRINT_SLIP_MSG";
export const SET_POSTCOMMENT = "SET_POSTCOMMENT"; ////to set 1 single comment on click of post button
export const GET_COMMENTS = "GET_COMMENTS"; //
export const SET_COMMENTS = "SET_COMMENTS"; //
export const DELETE_COMMENT = "DELETE_COMMENT";
export const EDIT_COMMENT = "EDIT_COMMENT";
export const SET_ORDER_ACTIVATIONINFO = "SET_ORDER_ACTIVATIONINFO";
export const GET_ORDER_ACTIVATIONINFO = "GET_ORDER_ACTIVATIONINFO";
export const SET_DEVALUATE_BTN_STATUS = "SET_DEVALUATE_BTN_STATUS";
export const SET_DEVALUATE_BTN_TITLE = "SET_DEVALUATE_BTN_TITLE";
export const SET_DEVALUATE_BTN_TITLE_INDEX = "SET_DEVALUATE_BTN_TITLE_INDEX";
export const SET_DEVALUATE_MODAL_TITLE = "SET_DEVALUATE_MODAL_TITLE";
export const SUBMIT_DEVALUATION = "SUBMIT_DEVALUATION";
export const CHECK_STATUS = "CHECK_STATUS";
export const CHECK_STATUS_BOOL = "CHECK_STATUS_BOOL";
export const CHECK_STATUS_DATA_RX = "CHECK_STATUS_DATA_RX";
export const DOWNLOAD_DEVALUATION_EXCEL = "DOWNLOAD_DEVALUATION_EXCEL";
export const SET_DEVALUATE_DATA = "SET_DEVALUATE_DATA";
export const SET_DEVALUATE_BTN_ARR = "SET_DEVALUATE_BTN_ARR";
export const SET_MERGED_DATA = "SET_MERGED_DATA";
export const SET_MERGED_COMMENTS = "SET_MERGED_COMMENTS";
export const SET_ORDER_SELECTED_STATUS = "SET_ORDER_SELECTED_STATUS";
export const SET_SUCCESS_DATA = "SET_SUCCESS_DATA";
export const SET_FAILED_DATA = "SET_FAILED_DATA";
export const SET_ACTIVATION_ORDER_LOCKED_MSG = "SET_ACTIVATION_ORDER_LOCKED_MSG";
export const SET_TRACKINGNO_VALUE = "SET_TRACKINGNO_VALUE"; 
export const SET_MSG_COLOR = "SET_MSG_COLOR"; 
export const SET_SEARCH_COMPLETE = "SET_SEARCH_COMPLETE"; 
export const SET_SEARCH_PARAM = "SET_SEARCH_PARAM"; 
export const SET_FUllFillMENT_CLICK = "SET_FUllFillMENT_CLICK"; 
export const GET_REFRESH_TOKEN = 'GET_REFRESH_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SET_TOKEN_EXPIRED = 'SET_TOKEN_EXPIRED';



export const setTokenExpired = (obj) => ({
  type: SET_TOKEN_EXPIRED,
  value: obj,
});
export const setSearchParam = (obj) => ({
  type: SET_SEARCH_PARAM,
  value: obj,
});

export const setSearchComplete = (orderId) => ({
  type: SET_SEARCH_COMPLETE,
  value: orderId,
});
export const setOrderSelectedStatus = (orderId) => ({
  type: SET_ORDER_SELECTED_STATUS,
  value: orderId,
});
export const setSuccessMessage = (orderId) => ({
  type: SET_SUCCESS_MESSAGE,
  value: orderId,
});
export const setSuccessMessageType = (orderId) => ({
  type: SET_SUCCESS_MESSAGE_TYPE,
  value: orderId,
});
export const getUnlockOrder = (orderId) => ({
  type: GET_UNLOCK_ORDER,
  value: orderId,
});
export const setUnlockOrder = (orderId) => ({
  type: SET_UNLOCK_ORDER,
  value: orderId,
});

export const getCard = (data, productData) => ({
  type: GET_CARD,
  value: data,
  productData: productData,
});

export const setPageReload = (data) => ({
  type: SET_PAGE_LOAD,
  value: data,
});
export const setOrderLockedBy = (data) => ({
  type: SET_ORDER_LOCKED_BY,
  value: data,
});

export const getFullFillMent = (orderid) => ({
  type: GET_FUllFillMENT,
  value: orderid,
});
export const setFullFillMentStatus = (orderid) => ({
  type: SET_FUllFillMENT_STATUS,
  value: orderid,
});
export const setExpandedOrder= (data) => ({
  type: SET_EXPANDED_ORDER,
  value: data,
});

export const setFullFillMentCompleted = (data) => ({
  type: SET_FUllFillMENT_COMPLETED,
  value: data,
});

export const setFullFillMent = (data) => ({
  type: SET_FUllFillMENT,
  value: data,
});


export const setSelectedProductItem = (items) => ({
  type: SET_SELECTED_PRODUCT_ITEM,
  value: items,
});

export const getAPIMKey = () => ({
  type: GET_APIMKEY,
});

export const setAPIMKey = (subKey, bogcSubKey) => ({
  type: SET_APIMKEY,
  subKey: subKey,
  bogcSubKey: bogcSubKey,
});

export const getOrderAditionalInfo = (orderNumber) => ({
  type: GET_ORDER_ADITIONALINFO,
  value: orderNumber,
});
export const setOrderAditionalInfo = (data) => ({
  type: SET_ORDER_ADITIONALINFO,
  value: data,
});

export const getOrderDetail = (orderNumber,items) => ({
  type: GET_ORDER_DETAIL,
  value: orderNumber,
  items: items,
});
export const setOrderDetail = (data) => ({
  type: SET_ORDER_DETAIL,
  value: data,
});

export const getAccessDetails = (userInfo) => ({
  type: GET_ACCESSDETAILS,
  value: userInfo,
});

export const setAccessDetails = (data) => ({
  type: SET_ACCESSDETAILS,
  value: data,
});

export const getUserSFKeyMap = () => ({
  type: GET_USERSFKEYMAP,
});

export const setUserSFKeyMap = (data) => ({
  type: SET_USERSFKEYMAP,
  value: data,
});

export const getRole = () => ({
  type: GET_ROLE,
});

export const setRole = (data) => ({
  type: SET_ROLE,
  value: data,
});

export const getDetails = (role) => ({
  type: GET_DETAILS,
  payload: role,
});

export const setDetails = (data) => ({
  type: SET_DETAILS,
  value: data,
});
export const getOrderStatus = () => ({
  type: GET_ORDER_STATUS,
});

export const setOrderStatus = (data) => ({
  type: SET_ORDER_STATUS,
  value: data,
});

export const getCompany = (company) => ({
  type: GET_COMPANY,
  value: company,
});

export const setCompanys = (data) => ({
  type: SET_COMPANY,
  value: data,
});

export const getContact = (contact, company) => ({
  type: GET_CONTACT,
  contact: contact,
  company: company,
});

export const setContacts = (data) => ({
  type: SET_CONTACT,
  value: data,
});

export const getStoreNumber = (storeText) => ({
  type: GET_STORENUMBER,
  value: storeText,
});

export const setStoreNumber = (data) => ({
  type: SET_STORENUMBER,
  value: data,
});

export const getOrderType = () => ({
  type: GET_ORDER_TYPE,
});

export const setOrderType = (data) => ({
  type: SET_ORDER_TYPE,
  value: data,
});

export const getProductSelection = () => ({
  type: GET_PRODUCT_SELECTION,
});

export const setProductSelection = (data) => ({
  type: SET_PRODUCT_SELECTION,
  value: data,
});

export const getDivisionSelection = () => ({
  type: GET_DIVISION_SELECTION,
});

export const setDivisionSelection = (data) => ({
  type: SET_DIVISION_SELECTION,
  value: data,
});
export const setDivision = (data) => ({
  type: SET_DIVISION,
  value: data,
});

export const getSearchResult = (
  searchObj,
  fieldName,
  sortBy,
  pageNo,
  rowCount
) => ({
  type: GET_SEARCHRESULT,
  value: searchObj,
  sortBy: sortBy,
  pageNo: pageNo,
  fieldName: fieldName,
  rowCount: rowCount,
});

export const setGiftCards = (data) => ({
  type: SET_GIFT_CARD,
  value: data,
});

export const getExportToExcel = (
  orderStatusOptions,
  searchObj,
  fieldName,
  sortBy,
  pageNo,
  rowCount,
  type
) => ({
  type: GET_EXPORTTOEXCEL,
  value: searchObj,
  sortBy: sortBy,
  pageNo: pageNo,
  fieldName: fieldName,
  rowCount: rowCount,
  exportType: type,
  orderStatusOptions: orderStatusOptions,
});
export const setSearchResult = (data) => ({
  type: SET_SEARCHRESULT,
  value: data,
});

export const getFileAttached = (orderNumber) => ({
  type: GET_FILEATTACHED,
  value: orderNumber,
});

export const setFilesToDisplay = (fileData) => ({
  type: SET_FILES_TO_DISPLAY,
  value: fileData,
});

export const setFileAttached = (
  fileName,
  fileNameWithExtension,
  fileContentLocation,
  salesforceEncryptedOrderId,
  fileBase64Data,
  svwpUserId,
  svwpUserName,
) => ({
  type: SET_FILEATTACHED,
  fileName: fileName,
  fileNameWithExtension: fileNameWithExtension,
  fileContentLocation: fileContentLocation,
  salesforceEncryptedOrderId: salesforceEncryptedOrderId,
  fileBase64Data: fileBase64Data,
  svwpUserId: svwpUserId,
  svwpUserName: svwpUserName,
});

export const downloadFileAttached = (fileId) => ({
  type: DOWNLOAD_FILEATTACHED,
  value: fileId,
});
export const deleteFileAttached = (fileId) => ({
  type: DELETE_FILEATTACHED,
  value: fileId,
});

// Step 2:packing slip actions -> download printPackingSlip file
export const setPrintPackingSlip = (data) => ({
  type: SET_PRINTPACKSLIP,
  value: data,
});
export const setPrintInvoice = (data) => ({
  type: SET_PRINT_INVOICE,
  value: data,
});
//step 3 : track package and save
// export const saveShippingInfo = (data) => ({
//   type: SET_PRINTPACKSLIP,
//   value: data,
// });

// export const trackPackageInfo = (data) => ({
//   type: SET_PRINTPACKSLIP,
//   value: data,
// });

export const setTextareaValue = (value) => ({
  type: SET_TEXTAREA_VALUE,
  value: value,
});

export const setTrackingNoValue = (value) => ({
  type: SET_TRACKINGNO_VALUE,
  value: value,
});

export const setStep3Status = (status) => ({
  type: SET_STEP3_STATUS,
  value: status,
});

export const setSaveBtnStatus = (status) => ({
  type: SET_SAVE_BTN_STATUS,
  value: status,
});

export const setTrackBtnStatus = (status) => ({
  type: SET_TRACK_BTN_STATUS,
  value: status,
});

export const setTrackTxtBoxStatus = (status) => ({
  type: SET_TRACK_TXT_BOX_STATUS,
  value: status,
});

//this action is only being used to set the message and no data
export const setTrackDataReceivedFromSave = (data) => ({
  type: SET_TRACK_DATA_RECEIVED_FROM_SAVE,
  value: data,
});

export const setFileMessage = (data) => ({
  type: SET_FILEMSG,
  value: data,
});
export const setPrintSlipMessage = (data) => ({
  type: SET_PRINT_SLIP_MSG,
  value: data,
});


//to set 1 single comment on click of post button
export const setPostCommentHistVal = (comment) => ({
  type: SET_POSTCOMMENT,
  value: comment,
});

//to fetch all comments related to an orderId
//this is where you put the data -> like setOrderId
export const setFetchedComments = (orderId) => ({
  type: GET_COMMENTS,
  value: orderId,
});

export const setCommentsData = (data) => ({
  type: SET_COMMENTS,
  value: data,
});

export const deleteComment = (commentId) => ({
  type: DELETE_COMMENT,
  value: commentId,
});

export const editComment = (data) => ({
  type: EDIT_COMMENT,
  value: data,
});
//this will set the data inside getActivationInfo call
export const setActivationInfo = (data) => ({
  type: SET_ORDER_ACTIVATIONINFO,
  value: data,
});
//this is for api call dispatch action
export const getActivationInfo = (data) => ({
  type: GET_ORDER_ACTIVATIONINFO,
  value: data,
});

export const setDevaluateBtnStatus = (status) => ({
  type: SET_DEVALUATE_BTN_STATUS,
  value: status,
});

export const setDevaluateBtnTitle = (title,index) => ({
  type: SET_DEVALUATE_BTN_TITLE,
  title: title,
  index: index,
});

// export const setDevaluateBtnTitle = (title, index) => ({
//   type: SET_DEVALUATE_BTN_TITLE,
//   title,
//   index,
//   });

export const setDevaluateBtnTitleIndex = (index) => ({
  type: SET_DEVALUATE_BTN_TITLE_INDEX,
  value: index,
});

export const setDevaluateModalTitle = (title) => ({
  type: SET_DEVALUATE_MODAL_TITLE,
  value: title,
});

export const submitDevaluation = (data) => ({
  type: SUBMIT_DEVALUATION,
  value: data,
});

export const checkStatusInfo = (data) => ({
  type: CHECK_STATUS,
  value: data,
});


export const setCheckStatusBool = (data) => ({
  type: CHECK_STATUS_BOOL,
  value: data,
});


export const setDataFromCheckStatus = (data) => ({
  type: CHECK_STATUS_DATA_RX,
  value: data,
})

export const setDownloadDevaluationExcel = (data) => ({
  type: DOWNLOAD_DEVALUATION_EXCEL,
  value: data,
})

export const setDevaluateData = (data) => ({
  type: SET_DEVALUATE_DATA,
  value: data,
})

export const setDevaluateBtnArr = (data) => ({
  type: SET_DEVALUATE_BTN_ARR,
  value: data,
})

export const setMergedData = (data) => ({
  type: SET_MERGED_DATA,
  value: data,
})

export const setMergedComments = (data) => ({
  type: SET_MERGED_COMMENTS,
  value: data,
})

export const setCheckStatusSuccess = (data) => ({
  type: SET_SUCCESS_DATA,
  value: data,
})

export const setCheckStatusFail = (data) => ({
  type: SET_FAILED_DATA,
  value: data,
})

export const setActivationOrderLockedMsg = (data) => ({
  type: SET_ACTIVATION_ORDER_LOCKED_MSG,
  value: data,
})

export const setFileUploadErrorMsgColor = (data) => ({
  type: SET_MSG_COLOR,
  value: data,
})

export const setFulfillmentClick = (data) => ({
  type: SET_FUllFillMENT_CLICK,
  value: data,
})

export const getRefreshAuthToken = () => ({
  type: GET_REFRESH_TOKEN,
});

// export const setRefreshAuthToken = (token) => ({
//   type: SET_REFRESH_TOKEN,
//   value: token,
// });