import jwt_decode from "jwt-decode";

export const authTokenCookie = (props) => {
  //gets cookie and returns it as a key=value object
  try {
    let cookies = document.cookie ? document.cookie.split(";") : null;
    let cookieVal;
    let idcookie;
    let idField;
    let fields;
    let cookieUrl;
    if (cookies) {
      let cookie = cookies.filter(cookie => cookie.includes("ent-abs-auth"));
      idcookie = cookies.find(cookie => cookie.includes("ent-abs-itkn"));
      cookieVal = cookie.length ? cookie[0] : null;
      cookieUrl=cookies.find(cookie=>cookie.includes("abs-url"));
      //console.log(cookieUrl,'cookieUrl');
    }
    fields = cookieVal ? cookieVal.split("=") : null;
    idField = idcookie ? idcookie.split("=") : null;
    const authCookie = {
      name: fields ? fields[1] : null,
    };
    let token = authCookie.name ? authCookie.name : null;
    let idToken = idField ? idField[1] : null;
    let decodedToken = token ? jwt_decode(token) : null;
    let decodedIdToken = idToken ? jwt_decode(idToken) : null;
  //  token = token && token.split("%22");
  //  idToken = idToken && idToken.split("%22");
   if (props) {
    let authorizedRoles = props.find((app) => app.applicationDetail.includes(`Bulk Order Gift Card`))
    console.log('authorizedRoles: ', authorizedRoles);
    
    let aRoles = authorizedRoles?.appUserRole?.length > 0 ? authorizedRoles.appUserRole.split(`,`) : undefined;
    console.log('aRoles: ', aRoles);
    let fRoles = aRoles?.filter((role) => decodedIdToken?.roles?.indexOf(role) !== -1);
    console.log('fRoles: ', fRoles);
    console.log('decodedIdToken.roles: ', decodedIdToken.roles);
    console.log('decodedIdToken.roles[0]: ', decodedIdToken.roles[0]);
    sessionStorage.setItem('userRole', fRoles)
    if(aRoles[0] === 'GiftCardSupervisor'){
      sessionStorage.setItem('aUserRole', aRoles[0]) 
    }
     console.log(sessionStorage.getItem('aUserRole'),"sessionStorage")
  }
  return {
      authCookie,
      token,
      idToken,
      userInfo: {
        name: (decodedIdToken && decodedIdToken.name) ? decodedIdToken.name : null,
        userId: (decodedIdToken && decodedIdToken.onpremisessamaccountname) ? decodedIdToken.onpremisessamaccountname : null,
        emailId: (decodedIdToken && decodedIdToken.preferred_username) ? decodedIdToken.preferred_username : null,
        userRole: (decodedIdToken && decodedIdToken.roles && decodedIdToken.roles[0]) ? sessionStorage.getItem('userRole')==="" ? "GiftCardSupervisor" :sessionStorage.getItem('userRole') : "GiftCardSupervisor",        allUserRole: (decodedIdToken && decodedIdToken.roles && decodedIdToken.roles[0]) ? decodedIdToken.roles : null,
        userStore: (decodedIdToken && decodedIdToken.swyFacilityCode) ? decodedIdToken.swyFacilityCode : null,
        userDistrict: (decodedIdToken && decodedIdToken.swyDistrict) ? decodedIdToken.swyDistrict : null,
        userDivision: (decodedIdToken && decodedIdToken.swyDivision) ? decodedIdToken.swyDivision : null,
      },
      authUrl:cookieUrl,
      tid: decodedToken ? decodedToken.tid : null,
      decodedToken: decodedToken,
      decodedIdToken:decodedIdToken
    }

  } catch (err) {
    console.log("CookieError:--------------");
    console.log(err);
    console.log("CookieErrorEnds:--------------");
  }

};
