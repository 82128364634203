export const utilConstants = {
  autoCompleteClassName: "autoCompleteSearch",
  autoCompleteId: "free-solo-demo",
  calendarInputType: "text",
  calenderInputClassName: "calendarTextStyle form-control inputStyle",
  datePlaceHolder: "MM/DD/YYYY",
  calenderClassName: "calendar",

}

export const divisionMockData = [
  { label: "Select Division", value: 356 },
  { label: "Albania", value: 355 },
  { label: "Argentina", value: 54 },
  { label: "Austria", value: 43 },
  { label: "Cocos Islands", value: 61 },
  { label: "Kuwait", value: 965 },
  { label: "Sweden", value: 46 },
  { label: "Venezuela", value: 58 }
];

export const top100Films = [
  { division: 'All', title: '' },
  { division: 'Denver', title: 'list1' },
  { division: 'Haggen', title: 'list1' },
  { division: 'Houston', title: 'list1' },
  { division: 'Jewel', title: 'list1' },
  { division: 'Intermountain', title: 'list1' },
  { division: "Mid-Atlantic", title: 'list1' },
  { division: 'NorCal', title: 'list1' },
  {
    division: 'Portland',
    title: 'list1',
  },
  { division: 'Seattle', title: 'list2' },
  { division: 'Shawl', title: 'list2' },
  {
    division: 'ShoCal',
    title: 'list2',
  },
  {
    division: 'Southwest',
    title: 'list2',
  },
  { division: 'Southern', title: 'list2' },
  { division: 'United', title: 'list2' },
];
// export const divisionMockData = [
//     "Albania",
//    "Argentina", 
//    "Austria", 
//    "Cocos Islands",
//    "Kuwait", 
//    "Sweden", 
//    "Venezuela"
// ];
export const districtMockData = [
  { title: 'Denver' },
  { title: 'Haggen' },
  { title: 'Houston', },
  { title: 'Jewel', },
  { title: 'Intermountain', },
  { title: "Mid-Atlantic", },
  { title: 'NorCal' },
  { title: 'Portland' },
  { title: 'Seattle', },
  { title: 'Shawl' },
  { title: 'ShoCal' },
  { title: 'Southwest' },
  { title: 'Southern' },
  { title: 'United' },
];

export const storeMockData = [
  { title: '1123' },
  { title: '1234' },
  { title: '1349', },
  { title: '1819', },
  { title: '1256', },
  { title: "2223", },
  { title: '4453' },
  { title: '5643' },
  { title: '1254', },
  { title: '2345' },
  { title: '9876' },
  { title: '9345' },
  { title: '1235' },
  { title: '0974' },
];

export const locationMockData = [
  { label: "Select Location", value: 356 },
  { label: "Albania", value: 355 },
  { label: "Argentina", value: 54 },
  { label: "Austria", value: 43 },
  { label: "Cocos Islands", value: 61 },
  { label: "Kuwait", value: 965 },
  { label: "Sweden", value: 46 },
  { label: "Venezuela", value: 58 }
];

export const userRole = "BackStage"

export const userDetails = {
  division: '',
  district: '',
  store: ''
}

export const ctlWebData = {
  caseId: '46572208',
  openDate: '04/20/2022',
  closedDate: 'NA',
  openDays: 25,
  customerName: '',
  phone: '(623)869-1665',
  email: 'mprak@safeway.com',
  contactStatus: 'Closed',
  cases: [{
    number: "122",
    date: "01/01/2022",
    storeNum: "1743",
    location: "Southwest",
    caseDes: "Description obj",
    caseSub: "Subject Item",
  },
  {
    number: "122",
    date: "01/01/2022",
    storeNum: "1743",
    location: "Southwest",
    caseDes: "Description obj",
    caseSub: "Subject Item",
  }],
  firstAttempt: {
    date: '2022-06-01 05:30:00',
    name: 'name',
    position: 'Store Director/Manager',
    result: 'Phone Busy'
  },
  secondAttempt: {
    date: '',
    name: '',
    position: '',
    result: ''
  },
  thirdAttempt: {
    date: '',
    name: '',
    position: '',
    result: ''
  },
  issueResolved: 'no',
  custReach: 'no',
  conversation: 'cc',
  recognition: 'rr',
  resolve: 'cfff'
}

export const userRoleVal = {
  giftCardPaymentServices: 'GiftCardPaymentServices',
  giftCardAPUsers: 'GiftCardAPUsers',
  giftcardDivisionUsers: 'GiftCardDivisionUsers',
  giftcardAccountingUser: 'GiftcardAccountingUsers',
  giftcardFulfillmentUser: 'GiftcardFulfillmentUsers',
  giftcardBusinessOwners: 'GiftcardBusinessOwners',
  BulkGiftCardUsers: 'BulkGiftCardUsers',  
  GiftCardSupportUser: 'GiftCardSupportUser',
}
export const userRoleValForPaymentService = {
  giftcardpaymentservices: 'giftcardpaymentservices',
}







