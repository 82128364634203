import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { UserInfo } from "CommonLibrary";
import HealthFile from "../common/HealthFile";
//import CapNavigation from "../common/CapNavigation";
// import { authTokenCookie } from '../utils/CookieMaker';
// import { Environment } from '../utils/Environment';

const GiftCards = lazy(() => import('../component/screens/giftCards'))
const InternalOrders = lazy(() => import('../component/screens/internalOrders'))
const InvoicePackingSlip = lazy(() => import('../component/screens/invoicePackingSlip'))
// const CtlWebForm = lazy(() => import('../component/containers/ctlWebForm'))
//const LOGIN_ROOT = `${Environment.getLoginUrl()}`;
//const LOGIN_ROOT =`http://localhost:3000`;

// let {authUrl}=authTokenCookie();
// authUrl=(authUrl!==undefined) && authUrl.replace(`abs-url=${LOGIN_ROOT}`,``);

const route = (<React.Fragment>
    <Routes>
        {/* <Route path="/dashboard" element={<DashBoard />} /> */}
        <Route path="/health" element={<HealthFile />} />
        <Route path="/" element={<GiftCards />} />
        <Route path="/internal/order/:orderid" element={<InternalOrders />} />
        <Route path="/tnt/invoicePackingSlip" element={<InvoicePackingSlip />} />
        <Route path="/myinfo" element={<UserInfo />} />
    </Routes>
</React.Fragment>
)

export default route
