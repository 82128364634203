import {
  SET_ORDER_DATA,
  SET_MESSAGE_TYPE,
  SET_MESSAGE,
} from "./invoicePackingSlipAction";
const initialState = {
  orderData: "",
  message: "",
  messageType: "",
};
const invoicePackingSlip = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_DATA:
      return Object.assign({}, state, {
        orderData: action.value,
      });
    case SET_MESSAGE_TYPE:
      return Object.assign({}, state, {
        messageType: action.value,
      });
    case SET_MESSAGE:
      return Object.assign({}, state, {
        message: action.value,
      });

    default:
      return state;
  }
};
export default invoicePackingSlip;
