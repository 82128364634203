import React from 'react';
import './Error404.css';
import { clearRedux } from '../../utils/utils';
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidMount() {
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    clearRedux('logout');
    document.cookie.split(";").map(cookies => {
      document.cookie = cookies.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      return cookies;
    });
    console.log(error, errorInfo, `errorinformation`);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div align="center">
          <h1> UnExpected Error - Refresh or Try after sometime. </h1>
          <h5> If issue still exist, please contact support team </h5>
          {/* //  <h5><button className={"returnHomeBtn"} onClick={() => navigate('/')} > Return Home </button></h5> */}
        </div>
      )

    }

    return this.props.children;
  }
}

export default ErrorBoundary;