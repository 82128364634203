import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from '../reducer/index'
import rootSaga from '../sagas/index'



const persistConfig = {
    key: 'CTL',
    storage: storage,
}
const reducerName = rootReducer;
const persistConfigs = persistConfig;
const persistedReducer = persistReducer(persistConfigs, reducerName)
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// Create the saga middleware
const sagaMiddleware = createSagaMiddleware()
// Mount it on the Store
const store = createStore(
    (persistedReducer),
    composeEnhancer(
        applyMiddleware(sagaMiddleware)
    )
)

// Then run the saga
sagaMiddleware.run(rootSaga)

// Render the application

export default store