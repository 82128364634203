export const SERVICE_ERROR=`SERVICE_ERROR`;
export const SERVICE_EXCEPTION=`SERVICE_EXCEPTION`;

export const serviceError=(params)=>({
    type:SERVICE_ERROR,
    error:params,
});

export const serviceException=(params)=>({
    type:SERVICE_EXCEPTION,
    error:params,
});