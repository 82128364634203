import React from 'react'; 
import { Fragment } from 'react';

export const HealthFile = () => {
  return (
    <Fragment>
        App is healthy
    </Fragment>
  );
}

export default HealthFile;