import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authTokenCookie } from 'utils/CookieMaker';
import { clearRedux } from '../utils/utils';
export const UserAuthorization = ({ children }) => {
    const [authorized, setAuthorized] = useState(true);
     let appAuthorization = useSelector((state) => state?.accessDetails?.accessDetails?.allAppDet?.filter((app) => {
        return app.applicationDetail === `Bulk Order Gift Card`

    }));

    //console.log(appAuthorization, `allAppUrl`);
    const { authUrl } = authTokenCookie();
    let dasboardUrl = useSelector((state) => state?.accessDetails?.accessDetails?.dashboardDet?.dashboardURL);
    useEffect(() => {
        if ((appAuthorization === undefined || appAuthorization?.length === 0) && dasboardUrl) {
            setTimeout(() => {
                clearRedux('logout');
                document.cookie.split(";").map(cookies => {
                    document.cookie = cookies.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                    return cookies;
                });
                window.location.replace(dasboardUrl);
            }, 3500);
            setAuthorized(false);
        }
    }, [appAuthorization, dasboardUrl])
    if (((appAuthorization !== undefined && appAuthorization?.length>0) && dasboardUrl)
    || (authUrl && authUrl.includes('internal') && (appAuthorization !== undefined && appAuthorization?.length!==0)))
        return (
            <>{children}</>
        )
    else if (!authorized && dasboardUrl) {
        return (
            <div align="center">
                <h4>Login Error: Access restricted</h4>
                <h3>Sorry, you are not Authorized to access the requested application.</h3>
                <h3>You will be automatically redirected to DashBoard screen with list of applications you have access in azure. </h3>
            </div>
        )
    }
    else {
        return null
    }
}

export default UserAuthorization;
