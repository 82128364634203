import { combineReducers } from 'redux';
import storageSession from 'redux-persist/lib/storage/session'
import localSession from 'redux-persist/lib/storage';
import giftCard from '../../component/screens/giftCards/giftCardReducer'
import giftCardSearch from '../../component/containers/giftCardSearch/giftCardSearchReducer'
import loading from '../../component/containers/loader/LoaderReducer'
import ServiceErrorReducer from '../../common/ServiceError/ServiceErrorReducer';
import accessDetails from './accessDetailsReducer';
import internalOrder from '../../component/screens/internalOrders/internalOrderReducer'
import comment from '../../component/containers/internalOrder/comment/commentReducer'
import searchResult from '../../component/containers/internalOrder/searchResult/searchResultReducer'
import InvoicePackingSlip from '../../component/screens/invoicePackingSlip/invoicePackingSlipReducer'
import InvoiceSearch from '../../component/containers/invoicePackingSlip/search/searchReducer'
import InvoiceResult from '../../component/containers/invoicePackingSlip/searchResult/searchResultReducer'

const rootReducer = combineReducers({
    loading: loading,
    giftCardSearch: giftCardSearch,
    giftCard: giftCard,
    internalOrder: internalOrder,
    internalOrderComment: comment,
    internalOrderResult: searchResult,
    invoicePackingSlip: InvoicePackingSlip,
    invoiceSearch: InvoiceSearch,
    invoiceResult: InvoiceResult,
    serviceError: ServiceErrorReducer,
    accessDetails: accessDetails
});


const appReducer = (state, action) => {
    if (action.type === 'CLEAR_STORE') {
        storageSession.removeItem(`persist:CTL`);
        localSession.removeItem(`persist:CTL`);
       
        state = undefined
    }
    if (action.type === 'CLEAR_GIFTCARD') {
        state = { ...state, giftCard: undefined }
    }
    if (action.type === 'CLEAR_GIFTCARDSEARCH') {
        state = { ...state, giftCardSearch: undefined }
    }
    if (action.type === 'CLEAR_SERVICEERROR') {
        state = { ...state, serviceError: undefined }
    }
    // if (action.type === 'CTLWEBFORM') {
    //     state = { ...state, ctlWebForm: undefined }
    // }
    
    if (action.type === 'CLEAR_ALL') {
        state = undefined
    }
    return rootReducer(state, action)
}
export default appReducer;