import {
  SET_ORDER_NUMBER,
  SET_ORDER_NUMBER_ERROR,
} from "./searchAction";
const initialState = {
  orderNumber: "",
  orderNumberError: "",
};
const comment = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_NUMBER:
      return Object.assign({}, state, {
        orderNumber: action.value,
      });
    case SET_ORDER_NUMBER_ERROR:
      return Object.assign({}, state, {
        orderNumberError: action.value,
      });
   
    default:
      return state;
  }
};
export default comment;
