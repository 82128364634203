import { SET_ACCESSDETAILS } from '../../component/screens/giftCards/giftCardAction';
const initialState = {
    accessDetails: [],
};
const AccessDetails = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCESSDETAILS:
            return Object.assign({}, state, {
                accessDetails: action.value
            })
        default:
            return state;
    }
}
export default AccessDetails; 