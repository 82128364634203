import {  SET_ORDERS,SET_COMMENT_ACTION } from "./internalOrderAction";
const initialState = {
  orderList: "",
  commentResp:"",
};
const internalOrder = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS:
      return Object.assign({}, state, {
        orderList: action.value,
      });
    case SET_COMMENT_ACTION:
      return Object.assign({}, state, {
        commentResp: action.value,
      });

    default:
      return state;
  }
};
export default internalOrder;
