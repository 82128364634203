import { SERVICE_ERROR, SERVICE_EXCEPTION } from "./ServiceErrorAction";

export const initialState = {
    error: ``,
    exception: ``,
}
export const ServiceErrorReducer = (state = initialState, action) => {

    switch (action.type) {
        case SERVICE_ERROR:
            return Object.assign({}, state, {
                error: action.error
            })
        
        case SERVICE_EXCEPTION:
            return Object.assign({}, state, {
                exception: action.error
            })
        default:
            return state;
    }
}
export default ServiceErrorReducer;

// const initialState = {
//     error: "",
//  };
//  const ServiceError = (state = initialState, action) => {
//     switch (action.type) {
//        case 'SERVICE_ERROR':
//           return Object.assign({}, state, {
//              error: action.value
//           })
//        default:
//           return state;
//     }
//  }
//  export default ServiceError;