export const GET_ORDERS = `GET_ORDERS`
export const SET_ORDERS = `SET_ORDERS`
export const GET_COMMENT_ACTION = `GET_COMMENT_ACTION`
export const SET_COMMENT_ACTION = `SET_COMMENT_ACTION`


export const getOrders = (data) => ({
    type: GET_ORDERS,
    value: data
})
export const setOrders = (data) => ({
    type: SET_ORDERS,
    value: data
})

export const getCommentAction = (data) => ({
    type: GET_COMMENT_ACTION,
    value: data
})
export const setCommentAction = (data) => ({
    type: SET_COMMENT_ACTION,
    value: data
})

