import {
    SET_DEFAULT_ORDER_STATUS,
    SET_DEFAULT_ORDER_TYPE,
    SET_DEFAULT_PRODUCT_SELECTION,
    SET_DEFAULT_DIVISION_SELECTION,
    SET_DEFAULT_COMPANY,
    SET_DEFAULT_CONTACT,
    SET_DEFAULT_ORDER_NUMBER,
    SET_DEFAULT_SERIAL_NUMBER,
    SET_DEFAULT_STORE_NUMBER,
    SET_DEFAULT_CHECK_NUMBER,
    SET_DEFAULT_PHONENUMBER,
    SET_DEFAULT_ORDER_RANGE,
    SET_DEFAULT_ACTIVATION_RANGE,
    SET_ORDERNUMBER_ERROR,
    SET_PHONENUMBER_ERROR,
    SET_CHECKNUMBER_ERROR,
    SET_SERIALNUMBER_ERROR,
    SET_COMPANY_ERROR,
    SET_CONTACT_ERROR,
    SET_STORE_ERROR,
    SET_WARNING_MESSAGE,
    SET_WARNING_POSITION,
    SET_RESET_CLICK,
    SET_ROLE_FILTER

} from './giftCardSearchAction';
const initialState = {
    defaultOrderType: [],
    defaultOrderStatus: [],
    defaultProductSelection: "",
    defaultDivisionSelection: "",
    company: "",
    contact: "",
    orderNumber: [],
    serialNumber: "",
    storeNumber: "",
    checkNumber: "",
    phoneNumber: "",
    orderRange: "",
    activationRange: "",
    orderNumberErrorMsg: '',
    phoneNumberErrorMsg: '',
    checkNumberErrorMsg: '',
    serialNumberErrorMsg: '',
    companyErrorMsg: '',
    contactErrorMsg: '',
    storeErrorMsg: '',
    warningMessage: '',
    warningPosition: '',
    reset_triggered:0,
    role_filter_completed:0

};
const giftCardSearch = (state = initialState, action) => {
    switch (action.type) {
        case SET_ROLE_FILTER:
            return Object.assign({}, state, {
                role_filter_completed: action.value
            })
        case SET_COMPANY_ERROR:
            return Object.assign({}, state, {
                companyErrorMsg: action.value
            })
        case SET_RESET_CLICK:
            return Object.assign({}, state, {
                reset_triggered: action.value
            })
        case SET_WARNING_MESSAGE:
            return Object.assign({}, state, {
                warningMessage: action.value
            })
        case SET_WARNING_POSITION:
            return Object.assign({}, state, {
                warningPosition: action.value
            })
        case SET_CONTACT_ERROR:
            return Object.assign({}, state, {
                contactErrorMsg: action.value
            })
        case SET_STORE_ERROR:
            return Object.assign({}, state, {
                storeErrorMsg: action.value
            })
        case SET_SERIALNUMBER_ERROR:
            return Object.assign({}, state, {
                serialNumberErrorMsg: action.value
            })
        case SET_CHECKNUMBER_ERROR:
            return Object.assign({}, state, {
                checkNumberErrorMsg: action.value
            })
        case SET_PHONENUMBER_ERROR:
            return Object.assign({}, state, {
                phoneNumberErrorMsg: action.value
            })
        case SET_ORDERNUMBER_ERROR:
            return Object.assign({}, state, {
                orderNumberErrorMsg: action.value
            })
        case SET_DEFAULT_ORDER_STATUS:
            return Object.assign({}, state, {
                defaultOrderStatus: action.value
            })
        case SET_DEFAULT_ORDER_TYPE:
            return Object.assign({}, state, {
                defaultOrderType: action.value
            })
        case SET_DEFAULT_PRODUCT_SELECTION:
            return Object.assign({}, state, {
                defaultProductSelection: action.value
            })
        case SET_DEFAULT_DIVISION_SELECTION:
            return Object.assign({}, state, {
                defaultDivisionSelection: action.value
            })
        case SET_DEFAULT_COMPANY:
            return Object.assign({}, state, {
                company: action.value
            })
        case SET_DEFAULT_CONTACT:
            return Object.assign({}, state, {
                contact: action.value
            })
        case SET_DEFAULT_ORDER_NUMBER:
            return Object.assign({}, state, {
                orderNumber: action.value
            })
        case SET_DEFAULT_SERIAL_NUMBER:
            return Object.assign({}, state, {
                serialNumber: action.value
            })
        case SET_DEFAULT_STORE_NUMBER:
            return Object.assign({}, state, {
                storeNumber: action.value
            })
        case SET_DEFAULT_CHECK_NUMBER:
            return Object.assign({}, state, {
                checkNumber: action.value
            })
        case SET_DEFAULT_PHONENUMBER:
            return Object.assign({}, state, {
                phoneNumber: action.value
            })
        case SET_DEFAULT_ORDER_RANGE:
            return Object.assign({}, state, {
                orderRange: action.value
            })
        case SET_DEFAULT_ACTIVATION_RANGE:
            return Object.assign({}, state, {
                activationRange: action.value
            })

        default:
            return state;
    }
}
export default giftCardSearch;