export const SET_DEFAULT_ORDER_STATUS = `SET_DEFAULT_ORDER_STATUS`
export const SET_DEFAULT_ORDER_TYPE = `SET_DEFAULT_ORDER_TYPE`
export const SET_DEFAULT_PRODUCT_SELECTION = `SET_DEFAULT_PRODUCT_SELECTION`
export const SET_DEFAULT_DIVISION_SELECTION = `SET_DEFAULT_DIVISION_SELECTION`
export const SET_DEFAULT_COMPANY = `SET_DEFAULT_COMPANY`
export const SET_DEFAULT_CONTACT = `SET_DEFAULT_CONTACT`
export const SET_DEFAULT_ORDER_NUMBER = `SET_DEFAULT_ORDER_NUMBER`
export const SET_DEFAULT_SERIAL_NUMBER = `SET_DEFAULT_SERIAL_NUMBER`
export const SET_DEFAULT_STORE_NUMBER = `SET_DEFAULT_STORE_NUMBER`
export const SET_DEFAULT_PHONENUMBER = `SET_DEFAULT_PHONENUMBER`
export const SET_DEFAULT_CHECK_NUMBER = `SET_DEFAULT_CHECK_NUMBER`
export const SET_DEFAULT_ORDER_RANGE = `SET_DEFAULT_ORDER_RANGE`
export const SET_DEFAULT_ACTIVATION_RANGE = `SET_DEFAULT_ACTIVATION_RANGE`
export const SET_ORDERNUMBER_ERROR = `SET_ORDERNUMBER_ERROR`
export const SET_PHONENUMBER_ERROR = `SET_PHONENUMBER_ERROR`
export const SET_CHECKNUMBER_ERROR = `SET_CHECKNUMBER_ERROR`
export const SET_SERIALNUMBER_ERROR = `SET_SERIALNUMBER_ERROR`
export const SET_COMPANY_ERROR = `SET_COMPANY_ERROR`
export const SET_CONTACT_ERROR = `SET_CONTACT_ERROR`
export const SET_STORE_ERROR = `SET_STORE_ERROR`
export const SET_WARNING_MESSAGE = `SET_WARNING_MESSAGE`
export const SET_WARNING_POSITION = `SET_WARNING_POSITION`
export const SET_RESET_CLICK = `SET_RESET_CLICK`
export const SET_ROLE_FILTER = `SET_ROLE_FILTER`

export const setRoleFilter = (msg) => ({
    type: SET_ROLE_FILTER,
    value:msg
})
export const setResetClick = (msg) => ({
    type: SET_RESET_CLICK,
    value:msg
})
export const setWarningMessage = (msg) => ({
    type: SET_WARNING_MESSAGE,
    value:msg
})
export const setWarningPosition = (data) => ({
    type: SET_WARNING_POSITION,
    value: data
});

export const setCompanyError = (data) => ({
    type: SET_COMPANY_ERROR,
    value: data
})
export const setContactError = (data) => ({
    type: SET_CONTACT_ERROR,
    value: data
})
export const setStoreError = (data) => ({
    type: SET_STORE_ERROR,
    value: data
})

export const setCheckNumberError = (data) => ({
    type: SET_CHECKNUMBER_ERROR,
    value: data
})
export const setSerialNumberError = (data) => ({
    type: SET_SERIALNUMBER_ERROR,
    value: data
})
export const setOrderNumberError = (data) => ({
    type: SET_ORDERNUMBER_ERROR,
    value: data
})
export const setPhoneError = (data) => ({
    type: SET_PHONENUMBER_ERROR,
    value: data
})
export const setDefaultOrderStatus = (data) => ({
    type: SET_DEFAULT_ORDER_STATUS,
    value: data
})

export const setDefaultOrderType = (data) => ({
    type: SET_DEFAULT_ORDER_TYPE,
    value: data
})

export const setDefaultProductSelection = (data) => ({
    type: SET_DEFAULT_PRODUCT_SELECTION,
    value: data
})

export const setDefaultDivisionSelection = (data) => ({
    type: SET_DEFAULT_DIVISION_SELECTION,
    value: data
})
export const setDefaultCompany = (data) => ({
    type: SET_DEFAULT_COMPANY,
    value: data
})
export const setDefaultContact = (data) => ({
    type: SET_DEFAULT_CONTACT,
    value: data
})
export const setDefaultOrderNumber = (data) => ({
    type: SET_DEFAULT_ORDER_NUMBER,
    value: data
})
export const setDefaultSerialNumber = (data) => ({
    type: SET_DEFAULT_SERIAL_NUMBER,
    value: data
})
export const setDefaultStoreNumber = (data) => ({
    type: SET_DEFAULT_STORE_NUMBER,
    value: data
})
export const setDefaultCheckNumber = (data) => ({
    type: SET_DEFAULT_CHECK_NUMBER,
    value: data
})
export const setDefaultPhoneNumber = (data) => ({
    type: SET_DEFAULT_PHONENUMBER,
    value: data
})
export const setDefaultOrderRange = (data) => ({
    type: SET_DEFAULT_ORDER_RANGE,
    value: data
})
export const setDefaultActivationRange = (data) => ({
    type: SET_DEFAULT_ACTIVATION_RANGE,
    value: data
})
