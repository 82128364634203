export class Environment {
   
    static getPublicUrl() {
        
        return process.env.PUBLIC_URL;
    }

    static getNodeEnvironment() {
        return process.env.NODE_ENV;
    }

    static getApiUrl() {
        return process.env.REACT_APP_API_URL;
    }
   
   static getDashboardApiUrl() {
        return process.env.REACT_APP_DASHBOARD_API_URL;
    }
    
    static getAccessDetailsApiUrl() {
        return process.env.REACT_APP_ACCESSDETAILS_API_URL;
    }

    static getLoginUrl() {
        return process.env.REACT_APP_BASE_URL;
    }
    
    static getSubscriptionKey() {
        return process.env.REACT_APP_APIM_KEY;
    }
    static getSecuritySubscriptionKey() {
        return process.env.REACT_APP_SEC_SUB_KEY;
    }
}
export default Environment;
