
import React, { useEffect,Suspense } from 'react';
import './App.css';
import { useSelector } from "react-redux";

import routes from './routes/index'
import { Container, Row, Col } from 'react-bootstrap'
import { Header, Footer } from "CommonLibrary"
import Loader from './component/containers/loader/index'
const loadingImg = <Loader loader="true"/>
const App = () => {
  const gcData = useSelector((state) => state.giftCard);
   useEffect(() => {
    document.querySelectorAll(".applist").forEach(el => el.remove());

  }, [])
  return (
    <div className="App">
      <Suspense fallback={loadingImg}>
        <Container fluid>
        
          <Row>
            {
              gcData?.role !== "GiftCardSupervisor" && <Header name="Customer Service Web Portal" />
            }
            
          </Row>
          <Row>
            <Col>
                {routes}
            </Col>
          </Row>
          <Row>
              <Footer />
          </Row>
        </Container>
      </Suspense>
    </div>
  );
}

export default App;
