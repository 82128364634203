import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { authTokenCookie } from "../utils/CookieMaker";
import {
  getAPIMKey,
  getAccessDetails,
  setTokenExpired,
  // setAccessDetails,
} from "../component/screens/giftCards/giftCardAction";
// import { logout } from "../utils/utils";

// import { useAccessDetails } from '../common/AccessDetails/useAccessDetails';
// import { useFieldDetails } from '../common/AccessDetails/useFieldDetails';

import { LOGIN_ROOT } from "../service/apiUrls";

export const AccessDetailsProvider = ({ children }) => {
  const { authCookie } = authTokenCookie();
  // const { fetchAccessDetails, accessDetailsError,accessDetailsLoading, accessDetailsData } = useAccessDetails();
  // const { fetchFieldDetails, fieldDetailsError, fieldDetailsLoading, fieldDetailsData } = useFieldDetails();
  const { userInfo } = authTokenCookie();
  const [authenticated, setAuthenticated] = useState(false);
  const [alertTimer, setAlertTimer] = useState(false);
  const [alertExpired, setAlertExpired] = useState(false);
  const dispatch = useDispatch();
  const gcData = useSelector((state) => state.giftCard);

  useEffect(() => {
    let loc = window.location.href;
    if (authCookie.name !== null && !authenticated) {
      //sessionid check removed for validating ediection issue
      dispatch(getAPIMKey());
      dispatch(getAccessDetails(userInfo));
      sessionStorage.setItem("sessionId", userInfo.userId);

      setAuthenticated(true);
    } else {
      //Clear browser session storage on logout.
      document.cookie.split(";").map((cookies) => {
        document.cookie = cookies
          .replace(/^ +/, "")
          .replace(
            /=.*/,
            "=;expires=" + new Date().toUTCString() + ";path=" + loc
          );
        return cookies;
      });
      sessionStorage.clear();
      sessionStorage.setItem("sessionId", userInfo.userId);
      window.location.replace(`${LOGIN_ROOT}/authenticate`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //     if (ctlData.key) {
  //         dispatch(getAccessDetails(userInfo))
  //     }
  // }, [ctlData.key, dispatch, userInfo])

  useEffect(() => {
    const timer = setInterval(() => {
      let currentDate = new Date();
      let currentTime = currentDate.getTime();
      let { decodedIdToken, userInfo, authCookie } = authTokenCookie();
      // JWT exp is in seconds
      let sessionId = sessionStorage.getItem("sessionId");
      if (authCookie && authCookie.name && sessionId === userInfo.userId) {
        // let tokenExpiry1 = (decodedIdToken.exp * 1000) - 3520000;
        // console.log('tokenExpiry1: ', tokenExpiry1);
        // let tokenExpiry = (decodedIdToken.exp-3500) * 1000;
        let tokenExpiry = (decodedIdToken.exp) * 1000;
        console.log(tokenExpiry,'====>tokenExpiry');
        // console.log(decodedIdToken.exp * 1000,'====>tokenExpiry org');
        console.log(currentTime,'====>currentTime');
        if (currentTime + 300000 >= tokenExpiry && !alertTimer) {
          //alert(`your session about to expire, please refresh the browser to retain the session`)
          setAlertTimer(true);
        } else if (tokenExpiry <= currentTime) {
          //  window.location.replace(`${LOGIN_ROOT}/refresh`);
          // logout();
          if(!alertExpired  && gcData?.isTokenExpired === 0){console.log('1111',alertExpired)
           dispatch(setTokenExpired(1))
           setAlertExpired(true);
          }
        }
      } else if (authenticated && authCookie.name === null) {
        // window.location.replace(`${LOGIN_ROOT}/refresh`);
       
        if(!alertExpired && gcData?.isTokenExpired === 0){console.log('3333',alertExpired)
          dispatch(setTokenExpired(1));
          setAlertExpired(true);
         }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [authenticated, alertTimer,dispatch,alertExpired,gcData?.isTokenExpired]);

  // const checkAccess = useCallback(() => {
  //     return (
  //         !accessDetailsLoading && !fieldDetailsLoading &&
  //         !accessDetailsError && !fieldDetailsError &&
  //         accessDetailsData && fieldDetailsData
  //     );
  // },[accessDetailsData, accessDetailsLoading, accessDetailsError, fieldDetailsError, fieldDetailsLoading, fieldDetailsData]);

  // if(accessDetailsError || fieldDetailsError ){
  //     const errorDetails = accessDetailsError ? accessDetailsError : fieldDetailsError;
  //     return (
  //         <ServiceError programName={"ACCESS DETAILS"} error={errorDetails} />
  //     );
  // }

  if (authenticated) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default AccessDetailsProvider;
