import { Environment } from '../utils/Environment';

export const LOGIN_ROOT = `${Environment.getLoginUrl()}`;
export const API_ROOT = `${Environment.getApiUrl()}`;
export const ACCESSDETAILS_API_ROOT = `${Environment.getAccessDetailsApiUrl()}`;
export const DASHBOARD_API_ROOT = `${Environment.getDashboardApiUrl()}`;

//export const LOGIN_ROOT = `http://localhost:8000`;
//export const API_ROOT = `https://svwp-bulkgiftcard-service-westus-dev-app-service-route.apps.np.stratus.albertsons.com`;
//export const DASHBOARD_API_ROOT = `https://svwp-dashboard-service-westus-dev-app-service-route.apps.np.stratus.albertsons.com`;

// let locURL = (LOGIN_ROOT.includes(`dev`) || LOGIN_ROOT.includes(`localhost`)) ? `dev` : (LOGIN_ROOT.includes(`qa`) ? `qa` :
//     (LOGIN_ROOT.includes(`perf`) ? `perf` : (LOGIN_ROOT.includes(`acceptance`) ? `perf` : (LOGIN_ROOT.includes(`stage`) ? `perf` : `prod`))));

export const apiUrl = {
    "testUrl": `https://jsonplaceholder.typicode.com/todos`,

}
export const getUserSFKeyMap = {
    url: `${API_ROOT}/customercomments/getSalesForceIdEncryption`,
    method: `POST`
}
export const getAccessDetail = {
    url: `${DASHBOARD_API_ROOT}/dashboard/getUserApplicationAccessDetails`,
    method: `POST`
}
export const getSubscriptionKey = {
    url: `${LOGIN_ROOT}/api/getSubKey`,
    method: `GET`
}

export const exportToExcelOrderList = {
    url: `${API_ROOT}/bogc/export-excel/orderlist`,
    method: 'POST'
}
export const exportToExcelActivated = {
    url: `${API_ROOT}/bogc/export-excel/activatedorderlist`,
    method: 'POST'
}
export const exportToExcelActivatedForJournal = {
    url: `${API_ROOT}/bogc/export-excel/activatedjournalorderlist`,
    method: 'POST'
}

export const getCompany = {
    url: `${API_ROOT}/bogc/companies`,
    method: 'GET'
}

export const getContact = {
    url: `${API_ROOT}/bogc/contacts`,
    method: 'GET'
}

export const getStore = {
    url: `${API_ROOT}/bogc/storenumber`,
    method: 'GET'
}

export const getOrderStatus = {
    url: `${API_ROOT}/bogc/orderstatus`,
    method: 'GET'
}
export const getOrderType = {
    url: `${API_ROOT}/bogc/ordertypes`,
    method: 'GET'
}
export const getDivisions = {
    url: `${DASHBOARD_API_ROOT}/dashboard/getDivisions`,
    method: 'GET'
}

export const getProductSelection = {
    url: `${API_ROOT}/bogc/productselection`,
    method: 'GET'
}
export const getOrderDetail = {
    url: `${API_ROOT}/bogc/order/orgcontactinfo`,
    method: 'GET'
}
export const getOrderAditionalInfo = {
    url: `${API_ROOT}/bogc/order/additionalinfo`,
    method: 'GET'
}

export const getSearchResult = {
    url: `${API_ROOT}/bogc/search`,
    method: 'POST'
}

export const getFulFillMent = {
    url: `${API_ROOT}/bogc`,
    method: 'POST'
}
export const getUnlockOrder = {
    url: `${API_ROOT}/bogc`,
    method: 'POST'
}
export const setFileAttachd = {
    url: `${API_ROOT}/bogc/fileupload`,
    method: `POST`
}

export const getFileAttachd = {
    url: `${API_ROOT}/bogc/activationattachments`,
    method: `GET`
}

export const    downloadFileAttachd = {
    url: `${API_ROOT}/bogc/activationattachments/downloadfile`,
    method: `GET`
}
export const deleteFileAttachd = {
    url: `${API_ROOT}/bogc/activationattachments/deletefile`,
    method: `DELETE`
}

//Print Packing Slip url : /bogc/export/printslip
export const printPackingSlipUri = {
    url: `${API_ROOT}/bogc/export/printslip`,
    method: `POST`
}

export const saveShippingInfoUri = {
    url: `${API_ROOT}/bogc/cards/save`,
    method: `POST`
}

export const trackPackageInfoUri = {
    url: `${API_ROOT}/bogc/export/printslip`,
    method: `POST`
}

export const getCard = {
    url: `${API_ROOT}/bogc/cards/search`,
    method: 'POST'
}

export const getOrders = {
    url: `${API_ROOT}/bogc/internal/order`,
    method: 'GET'
}

export const getComment = {
    url: `${API_ROOT}/bogc/internal/orderapproval`,
    method: 'POST'
}
export const setPostCommHistUri = {
    url: `${API_ROOT}/bogc/commenthistory/postcomment`,
    method: 'POST'
}

export const editCommUri = {
    url: `${API_ROOT}/bogc/commenthistory/editcomment`,
    method: 'PATCH'
}

///commenthistory/getcomments/{orderId}
export const fetchCommentsUri = {
    url: `${API_ROOT}/bogc/commenthistory/getcomments`,
    method: 'GET'
}

// /commenthistory/deletecomment/{commentId}
export const deletePostCommHistUri = {
    url: `${API_ROOT}/bogc/commenthistory/deletecomment`,
    method: `DELETE`
}

export const activationinformationUri = {
    url: `${API_ROOT}/bogc/activationinformation`,
    method: `GET`
}

// /devaluationdetails/{firstcardnumber}
export const submitDevaluationUri = {
    url: `${API_ROOT}/bogc/deactivation`,
    method: `POST`
}

export const checkStatusUri = {
    url: `${API_ROOT}/bogc/devaluationdetails/checkstatus`,
    method: `POST`
}

export const downloadDevaluationExcelUri = {
    url: `${API_ROOT}/bogc/devaluationdetails/checkstatus/export-excel`,
    method: `POST`
}

export const printInvoiceUri = {
    url: `${API_ROOT}/bogc/export/invoice`,
    method: `GET`
}
export const getOrderNumber = {
    url: `${API_ROOT}/outsystem/order`,
    method: `GET`
}

export const getPrintSlip = {
    url: `${API_ROOT}/outsystem/export/printslip`,
    method: `GET`
}

export const getInvoices = {
    url: `${API_ROOT}/outsystem/export/invoice`,
    method: `GET`
}

export const sendEmails = {
    url: `${API_ROOT}/outsystem/email/invoice`,
    method: `POST`
}
