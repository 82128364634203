import React from 'react'
import './loader.css'
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { Backdrop } from '@mui/material'

/**
 * @component Loader
 * component to implement loading during APi calls 
 * or any other operation that takes some time
 * @param {loader} props 
 */
const Loader = (props) => {
    // const [open, setOpen] = useState(false);
    const loaderFlag = useSelector((state) => state.loading.isLoading);
    
    // useEffect(() => {   
    //     if (props && props.loader) {
    //        setOpen(props.loader)
    //     }
    //     else if (loaderFlag) {
    //         setOpen(loaderFlag)
    //     }

    // }, [])

    return (
        <div >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loaderFlag}
            >
                <div>
                    <Spinner animation="border" />
                </div>
                <div className="message">Loading</div>
            </Backdrop>
        </div>
    )
}

export default Loader
