import {
  GET_ROLE,
  SET_ROLE,
  GET_DETAILS,
  SET_DETAILS,
  SET_APIMKEY,
  GET_APIMKEY,
  GET_USERSFKEYMAP,
  SET_USERSFKEYMAP,
  SET_SEARCHRESULT,
  SET_EXPORTTOEXCEL,
  SET_ORDER_STATUS,
  SET_COMPANY,
  SET_CONTACT,
  SET_STORENUMBER,
  SET_ORDER_TYPE,
  SET_PRODUCT_SELECTION,
  SET_DIVISION_SELECTION,
  SET_DIVISION,
  SET_GIFT_CARD,
  SET_ORDER_DETAIL,
  SET_ORDER_ADITIONALINFO,
  SET_SELECTED_PRODUCT_ITEM,
  SET_FUllFillMENT,
  SET_PAGE_LOAD,
  SET_ORDER_LOCKED_BY,
  SET_PRINTPACKSLIP,
  SET_TEXTAREA_VALUE,
  SET_TRACKINGNO_VALUE,
  SET_STEP3_STATUS,
  SET_SAVE_BTN_STATUS,
  SET_TRACK_BTN_STATUS,
  SET_TRACK_TXT_BOX_STATUS,
  SET_TRACK_DATA_RECEIVED_FROM_SAVE,
  SET_UNLOCK_ORDER,
  SET_SUCCESS_MESSAGE,
  SET_SUCCESS_MESSAGE_TYPE,
  SET_FUllFillMENT_STATUS,
  SET_FUllFillMENT_COMPLETED,
  GET_FILEATTACHED,
  // SET_FILEATTACHED,
  SET_FILES_TO_DISPLAY,
  DOWNLOAD_FILEATTACHED,
  DELETE_FILEATTACHED,
  SET_FILEMSG,
  SET_PRINT_SLIP_MSG,
  // SET_POSTCOMMENT,
  GET_COMMENTS,
  SET_COMMENTS,
  SET_ORDER_ACTIVATIONINFO,
  SET_DEVALUATE_BTN_STATUS,
  SET_DEVALUATE_BTN_TITLE,
  SET_DEVALUATE_BTN_TITLE_INDEX,
  SET_DEVALUATE_MODAL_TITLE,
  CHECK_STATUS_BOOL,
  CHECK_STATUS_DATA_RX,
  SET_DEVALUATE_DATA,
  SET_DEVALUATE_BTN_ARR,
  SET_MERGED_DATA,
  SET_MERGED_COMMENTS,
  SET_ORDER_SELECTED_STATUS,
  SET_SUCCESS_DATA,
  SET_FAILED_DATA,
  SET_ACTIVATION_ORDER_LOCKED_MSG,
  SET_MSG_COLOR,
  SET_EXPANDED_ORDER,
  SET_SEARCH_COMPLETE,
  SET_SEARCH_PARAM,
  SET_FUllFillMENT_CLICK,
  SET_TOKEN_EXPIRED,
} from "./giftCardAction";
const initialState = {
  role: "",
  orderSelectedStatus: 0,
  isTokenExpired: 0,
  searchObj: "",
  searchComplete: 0,
  expandedOrder: "",
  key: "4e1fdce9265a41cf87c4df9bea4ecef4",
  bogcKey: "6f89b4c19cde40599bb0cc76eb63d102",
  searchResult: "",
  giftCardData: {
    searchObj: "",
    FieldName: "",
    SortBy: "",
    PageNo: "",
    RowCount: "",
    userRole: "",
  },
  selectedOrder: "",
  isFulFillmentClicked: 0,
  selectedProductItems: "",
  selectedOrderAditionalInfo: "",
  fullFillMentInfo: "",
  isFullFillMentStarted: 0,
  isFullFillMentCompleted: 0,
  orderLockedByCurrentUser: 0, // o default, 1 by current user, 2 by another user,
  isPageLoad: 0,
  isOrderUnlock: 0,
  successMsg: "",
  successMsgType: "",
  printPackSlip: "",
  textareaValue: "",
  step3status: false,
  saveBtnStatus: true,
  trackBtnStatus: true,
  trackTxtBoxStatus: false,
  trackingNumber: "",
  trackDataReceivedFromSave: "",
  fileData: [],
  fileOrderNum: "",
  fileId: "",
  fileMsg:
    "Only outlook, docx, txt, pdf, xls, ppt, jpg, jpeg, bmp, png files are allowed",
  printSlipMessage: "",
  comments: [],
  orderId: "",
  selectedOrderActivationInfo: "",
  devaluateBtnStatus: false,
  // devaluateBtnTitle: "Devaluate",
  devaluateBtnTitle: [],
  devaluateBtnTitleIndex: "",
  devaluationModalToShow: "",
  checkStatusBool: true,
  checkStatusDataRx: "",
  successDataRx: "",
  failedDataRx: "",
  devaluateData: "",
  devaluateButtonArray: [],
  mergedData: [],
  mergedComments: [],
  activationOrderLockedMsg: "",
  fileUploadErrorMsgColor: "",
};

const GiftCard = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_PARAM:
      return Object.assign({}, state, {
        searchObj: action.value,
      });
    case SET_TOKEN_EXPIRED:
      return Object.assign({}, state, {
        isTokenExpired: action.value,
      });
    case SET_SEARCH_COMPLETE:
      return Object.assign({}, state, {
        searchComplete: action.value,
      });
    case SET_EXPANDED_ORDER:
      return Object.assign({}, state, {
        expandedOrder: action.value,
      });
    case SET_ORDER_SELECTED_STATUS:
      return Object.assign({}, state, {
        orderSelectedStatus: action.value,
      });
    case SET_SUCCESS_MESSAGE:
      return Object.assign({}, state, {
        successMsg: action.value,
      });
    case SET_SUCCESS_MESSAGE_TYPE:
      return Object.assign({}, state, {
        successMsgType: action.value,
      });
    case SET_UNLOCK_ORDER:
      return Object.assign({}, state, {
        isOrderUnlock: action.value,
      });
    case SET_ORDER_LOCKED_BY:
      return Object.assign({}, state, {
        orderLockedByCurrentUser: action.value,
      });
    case SET_PAGE_LOAD:
      return Object.assign({}, state, {
        isPageLoad: action.value,
      });

    case SET_FUllFillMENT:
      return Object.assign({}, state, {
        fullFillMentInfo: action.value,
      });

    case SET_SELECTED_PRODUCT_ITEM:
      return Object.assign({}, state, {
        selectedProductItems: action.value,
      });
    case GET_ROLE:
      return Object.assign({}, state, {
        role: action.value,
      });
    case SET_ROLE:
      return Object.assign({}, state, {
        role: action.value,
      });
    case GET_APIMKEY:
      return Object.assign({}, state, {
        key: action.value,
      });
    case SET_APIMKEY:
      return Object.assign({}, state, {
        key: action.subKey,
        bogcKey: action.bogcSubKey,
      });
    case GET_USERSFKEYMAP:
      return Object.assign({}, state, {
        userSFMap: action.value,
      });
    case SET_USERSFKEYMAP:
      return Object.assign({}, state, {
        userSFMap: action.value,
      });
    case GET_DETAILS:
      return Object.assign({}, state, {
        details: action.value,
      });
    case SET_SEARCHRESULT:
      return Object.assign({}, state, {
        searchResult: action.value,
      });
    case SET_DETAILS:
      return Object.assign({}, state, {
        details: action.value,
      });

    case SET_ORDER_STATUS:
      return Object.assign({}, state, {
        orderStatus: action.value,
      });
    case SET_COMPANY:
      return Object.assign({}, state, {
        company: action.value,
      });
    case SET_CONTACT:
      return Object.assign({}, state, {
        contact: action.value,
      });
    case SET_STORENUMBER:
      return Object.assign({}, state, {
        storeNumber: action.value,
      });
    case SET_ORDER_TYPE:
      return Object.assign({}, state, {
        orderType: action.value,
      });
    case SET_PRODUCT_SELECTION:
      return Object.assign({}, state, {
        productSelection: action.value,
      });
    case SET_DIVISION_SELECTION:
      return Object.assign({}, state, {
        divisionSelection: action.value,
      });
    case SET_DIVISION:
      return Object.assign({}, state, {
        divisions: action.value,
      });

    case SET_GIFT_CARD:
      return Object.assign({}, state, {
        giftCardData: action.value,
      });
    case SET_EXPORTTOEXCEL:
      return Object.assign({}, state, {
        exportToExcel: action.value,
      });
    case SET_ORDER_DETAIL:
      return Object.assign({}, state, {
        selectedOrder: action.value,
      });
    case SET_ORDER_ADITIONALINFO:
      return Object.assign({}, state, {
        selectedOrderAditionalInfo: action.value,
      });
    case SET_PRINTPACKSLIP:
      return Object.assign({}, state, {
        printPackSlip: action.value,
      });

    case SET_TEXTAREA_VALUE:
      return Object.assign({}, state, {
        textareaValue: action.value,
      });
    case SET_TRACKINGNO_VALUE:
      return Object.assign({}, state, {
        trackingNumber: action.value,
      });

    case SET_STEP3_STATUS:
      return Object.assign({}, state, {
        step3status: action.value,
      });

    case SET_SAVE_BTN_STATUS:
      return Object.assign({}, state, {
        saveBtnStatus: action.value,
      });

    case SET_TRACK_BTN_STATUS:
      return Object.assign({}, state, {
        trackBtnStatus: action.value,
      });

    case SET_TRACK_TXT_BOX_STATUS:
      return Object.assign({}, state, {
        trackTxtBoxStatus: action.value,
      });

    case SET_TRACK_DATA_RECEIVED_FROM_SAVE:
      return Object.assign({}, state, {
        trackDataReceivedFromSave: action.value,
      });
    case SET_FUllFillMENT_STATUS:
      return Object.assign({}, state, {
        isFullFillMentStarted: action.value,
      });
    case SET_FUllFillMENT_COMPLETED:
      return Object.assign({}, state, {
        isFullFillMentCompleted: action.value,
      });
    case GET_FILEATTACHED:
      return Object.assign({}, state, {
        fileOrderNum: action.value,
      });
    // case SET_FILEATTACHED:
    //   const { values } = action;
    //   // check
    //   return Object.assign({}, state, {
    //     fileData: action.fileName,
    //   });
    case SET_FILES_TO_DISPLAY:
      return Object.assign({}, state, {
        fileData: action.value,
      });
    case DOWNLOAD_FILEATTACHED:
      return Object.assign({}, state, {
        fileId: action.value,
      });
    case DELETE_FILEATTACHED:
      return Object.assign({}, state, {
        fileData: state.fileData.filter((file) => file.id !== action.value),
      });
    case SET_FILEMSG:
      return Object.assign({}, state, {
        fileMsg: action.value,
      });
    case SET_PRINT_SLIP_MSG:
      return Object.assign({}, state, {
        printSlipMessage: action.value,
      });
    case SET_COMMENTS: //this should populate fetched comments value
      return Object.assign({}, state, {
        comments: action.value,
      });
    case GET_COMMENTS: //this should populate fetched comments value
      return Object.assign({}, state, {
        orderId: action.value,
      });
    case SET_ORDER_ACTIVATIONINFO:
      return Object.assign({}, state, {
        selectedOrderActivationInfo: action.value,
      });
    case SET_DEVALUATE_BTN_STATUS:
      return Object.assign({}, state, {
        devaluateBtnStatus: action.value,
      });
    case SET_DEVALUATE_BTN_TITLE:
      const updatedTitles = [...state.devaluateBtnTitle];
      updatedTitles[action.index] = action.title;
      return {
        ...state,
        devaluateBtnTitle: updatedTitles,
      };
    case SET_DEVALUATE_BTN_TITLE_INDEX:
      return Object.assign({}, state, {
        devaluateBtnTitleIndex: action.value,
      });
    case SET_DEVALUATE_MODAL_TITLE:
      return Object.assign({}, state, {
        devaluationModalToShow: action.value,
      });
    case CHECK_STATUS_BOOL:
      return Object.assign({}, state, {
        checkStatusBool: action.value,
      });
    case CHECK_STATUS_DATA_RX:
      return Object.assign({}, state, {
        checkStatusDataRx: action.value,
      });
    case SET_SUCCESS_DATA:
      return Object.assign({}, state, {
        successDataRx: action.value,
      });
    case SET_FAILED_DATA:
      return Object.assign({}, state, {
        failedDataRx: action.value,
      });
    case SET_DEVALUATE_DATA:
      return Object.assign({}, state, {
        devaluateData: action.value,
      });
    case SET_DEVALUATE_BTN_ARR:
      return Object.assign({}, state, {
        devaluateButtonArray: action.value,
      });
    case SET_MERGED_DATA:
      return Object.assign({}, state, {
        mergedData: action.value,
      });
    case SET_MERGED_COMMENTS:
      return Object.assign({}, state, {
        mergedComments: action.value,
      });
    case SET_ACTIVATION_ORDER_LOCKED_MSG:
      return Object.assign({}, state, {
        activationOrderLockedMsg: action.value,
      });
    case SET_MSG_COLOR:
      return Object.assign({}, state, {
        fileUploadErrorMsgColor: action.value,
      });
    case SET_FUllFillMENT_CLICK:
      return Object.assign({}, state, {
        isFulFillmentClicked: action.value,
      });
    default:
      return state;
  }
};
export default GiftCard;
