export const GET_ORDER_DATA = `GET_ORDER_DATA`
export const SET_ORDER_DATA = `SET_ORDER_DATA`

export const GET_PRINT_SLIP = `GET_PRINT_SLIP`

export const GET_INVOICE = `GET_INVOICE`
export const SEND_EMAIL = `SEND_EMAIL`

export const SET_MESSAGE_TYPE = `SET_MESSAGE_TYPE`
export const SET_MESSAGE = `SET_MESSAGE`

export const getOrderData = (orderNuber) => ({
    type: GET_ORDER_DATA,
    value: orderNuber
})

export const setOrderData = (data) => ({
    type: SET_ORDER_DATA,
    value: data
})


export const getPrintSlip = (orderNuber) => ({
    type: GET_PRINT_SLIP,
    value: orderNuber
})

export const sendEmail = (orderNuber) => ({
    type: SEND_EMAIL,
    value: orderNuber
})


export const getInvoice = (orderNuber) => ({
    type: GET_INVOICE,
    value: orderNuber
})

export const setMessage = (data) => ({
    type: SET_MESSAGE,
    value: data
})

export const setMessageType = (data) => ({
    type: SET_MESSAGE_TYPE,
    value: data
})

