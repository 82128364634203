import { put, takeLatest, all } from "redux-saga/effects";
import moment from "moment";
import { api, exportApi, nodeApi, apiDeval } from "../../service/apiService";
import {
  getSubscriptionKey,
  getAccessDetail,
  getCompany,
  getOrderStatus,
  getDivisions,
  getProductSelection,
  getOrderType,
  getContact,
  getStore,
  getSearchResult,
  getOrderDetail,
  exportToExcelOrderList,
  exportToExcelActivated,
  exportToExcelActivatedForJournal,
  getOrderAditionalInfo,
  getFulFillMent,
  printPackingSlipUri,
  printInvoiceUri,
  saveShippingInfoUri,
  getCard,
  getUnlockOrder,
  setFileAttachd,
  getFileAttachd,
  downloadFileAttachd,
  deleteFileAttachd,
  fetchCommentsUri,
  setPostCommHistUri,
  deletePostCommHistUri,
  editCommUri,
  getOrders,
  getComment,
  // deleteFileAttachd,
  activationinformationUri,
  submitDevaluationUri,
  checkStatusUri,
  downloadDevaluationExcelUri,
  getOrderNumber,
  getPrintSlip,
  getInvoices,
  sendEmails,
  // refreshTokenUri,
} from "../../service/apiUrls";
import { authTokenCookie } from "../../utils/CookieMaker";
import { userRole } from "../../utils/constants";
import { serviceInProgress } from "../../component/containers/loader/LoaderAction";
import {
  GET_ACCESSDETAILS,
  setAccessDetails,
  GET_APIMKEY,
  setAPIMKey,
  setRole,
  GET_ROLE,
  GET_ORDER_STATUS,
  setOrderStatus,
  GET_COMPANY,
  GET_ORDER_TYPE,
  setOrderType,
  GET_PRODUCT_SELECTION,
  setProductSelection,
  setDivisionSelection,
  GET_DIVISION_SELECTION,
  GET_SEARCHRESULT,
  setSearchResult,
  GET_CONTACT,
  GET_STORENUMBER,
  setStoreNumber,
  setContacts,
  setCompanys,
  GET_ORDER_DETAIL,
  setOrderDetail,
  GET_EXPORTTOEXCEL,
  GET_ORDER_ADITIONALINFO,
  setOrderAditionalInfo,
  setSelectedProductItem,
  GET_FUllFillMENT,
  setFullFillMent,
  // setFullFillMentStarted,
  setOrderLockedBy,
  SET_PRINTPACKSLIP,
  // setPrintPackingSlip,
  // setTrackDataReceivedFromSave,
  SET_TRACK_DATA_RECEIVED_FROM_SAVE,
  GET_CARD,
  GET_UNLOCK_ORDER,
  setUnlockOrder,
  setSuccessMessage,
  setSuccessMessageType,
  setFullFillMentStatus,
  setFullFillMentCompleted,
  // setFileAttached,
  setFilesToDisplay,
  GET_FILEATTACHED,
  SET_FILEATTACHED,
  DELETE_FILEATTACHED,
  DOWNLOAD_FILEATTACHED,
  GET_COMMENTS,
  SET_POSTCOMMENT,
  setFetchedComments,
  setCommentsData,
  // SET_COMMENTS,
  DELETE_COMMENT,
  EDIT_COMMENT,
  getFileAttached,
  setFileMessage,
  setFileUploadErrorMsgColor,
  setPrintSlipMessage,
  // SET_ORDER_ACTIVATIONINFO,
  setActivationInfo,
  GET_ORDER_ACTIVATIONINFO,
  // setDevaluateBtnStatus,
  setDevaluateModalTitle,
  setCheckStatusBool,
  setDevaluateBtnTitle,
  SUBMIT_DEVALUATION,
  CHECK_STATUS,
  setDataFromCheckStatus,
  // setCheckStatusFail,
  DOWNLOAD_DEVALUATION_EXCEL,
  setDivision,
  setActivationOrderLockedMsg,
  setSaveBtnStatus,
  SET_PRINT_INVOICE,
  setSearchComplete,
  // GET_REFRESH_TOKEN,
} from "../../component/screens/giftCards/giftCardAction";

import {
  setWarningMessage,
  setWarningPosition,
} from "../../component/containers/giftCardSearch/giftCardSearchAction";
// import { GET_CASE_ID_FORM, GET_CLOSE_CONTACT, GET_SAVE_NOT_CLOSED, setCaseIdForm, setCloseContact, setSaveNotClosed, setContact, setPosition, GET_CONTACT, GET_POSITION } from '../../component/containers/ctlWebForm/ctlWebFormAction'
import { call, select } from "redux-saga/effects";
import * as selectors from "./selectors";
// import {ctlWebData} from '../../utils/constants'
// import { SERVICE_ERROR, SERVICE_EXCEPTION, serviceError, serviceException } from '../../common/ServiceError/ServiceErrorAction';
import { serviceError } from "../../common/ServiceError/ServiceErrorAction";
import {
  formAutoCompleteData,
  formSelectData,
  getOrderStatusList,
  getProductId,
  updateProductItems,
} from "utils/utils";
import {
  GET_COMMENT_ACTION,
  GET_ORDERS,
  setCommentAction,
  setOrders,
} from "component/screens/internalOrders/internalOrderAction";
import { setCommentPosted } from "component/containers/internalOrder/comment/commentAction";
import {
  GET_INVOICE,
  GET_ORDER_DATA,
  GET_PRINT_SLIP,
  SEND_EMAIL,
  setMessage,
  setMessageType,
  setOrderData,
} from "component/screens/invoicePackingSlip/invoicePackingSlipAction";

function exceptionFormatter(exp) {
  if (exp.config) {
    return {
      message:
        "System is unable to process this request at the moment, please contact the System Administrator.",
    };
  } else return { exp, url: ``, method: `` };
}
function* get_SubKey() {
  try {
    let response = yield call(() => {
      return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
    });
    // let response = yield call(() => { return nodeApi(`https://svwp-ctl-ui-dev.apps.np.stratus.albertsons.com/api/getSubKey`,`GET`) });
    let key = response && response.data && response.data.subKey;
    let bogcSubKey = response && response.data && response.data.bogcSubKey;
    yield put(setAPIMKey(key, bogcSubKey));
  } catch (exp) {
    console.log(`APIM Key exception`, exp);
  }
}

function isJWTNotExpired() {
  let tokenNotExpired = true;
  let currentDate = new Date();
  let currentTime = currentDate.getTime();
  let { decodedIdToken, authCookie, userInfo } = authTokenCookie();
  // JWT exp is in seconds
  let sessionId = sessionStorage.getItem("sessionId");

  if (authCookie.name && sessionId === userInfo.userId) {
    let tokenExpiry = decodedIdToken.exp * 1000;
    if (tokenExpiry < currentTime) {
      tokenNotExpired = false;
    }
  }
  return tokenNotExpired;
}

function* get_AccessDetails(userInfo) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      yield put(setOrderDetail(""));
      // JWTExpirationCheck();
      let subKey = yield select(selectors.key);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.subKey;
      }
      let userNameRole = [];

      console.log("userInfo: ", userInfo);
      if (userInfo?.value?.allUserRole !== null) {
        userInfo?.value?.allUserRole.forEach((userRole, index) => {
          userNameRole.push(userRole);
        });
      }
      let bogcRoles = [
        "GiftCardPaymentServices",
        "GiftCardAPUsers",
        "GiftCardDivisionUsers",
        "GiftcardAccountingUsers",
        "GiftcardFulfillmentUsers",
        "GiftcardBusinessOwners",
        "giftcardpaymentservices",
        "BulkGiftCardUsers",
        "GiftCardSupportUser",
      ];
      const isBogcRoleFound = userNameRole.some((r) => bogcRoles.includes(r));
      console.log("isBogcRoleFound: ", isBogcRoleFound);

      let request = {
        userNameRole:
          userInfo?.value?.allUserRole === null
            ? "GiftCardSupervisor"
            : isBogcRoleFound
            ? userNameRole.join(`;`)
            : "GiftCardSupervisor",

        // userNameRole: "GiftCardSupervisor",
        userName: userInfo?.value?.userId,
      };

      let res = yield call(() => {
        return api(
          getAccessDetail.url,
          getAccessDetail.method,
          request,
          subKey
        );
      });

      yield put(setAccessDetails(res?.data));
      // authTokenCookie(
      //   res?.data?.allAppDet.filter(
      //     (app) => app.appUserRole !== "AlbertsonUsers"
      //   )
      // );
      authTokenCookie(res?.data?.allAppDet);
      // yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}
function* get_OrderStatus() {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let data;

      let res = yield call(() => {
        return api(getOrderStatus.url, getOrderStatus.method, ``, subKey);
      });
      if (res.status === 200) {
        data = formAutoCompleteData("Select All", res.data);
        yield put(setOrderStatus(data));
      } else {
        yield put(setOrderStatus([]));
      }

      // yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}
function* get_Company(requestData) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }
      let data;

      let res = yield call(() => {
        return api(
          getCompany.url + `?typeText=` + requestData.value,
          getCompany.method,
          ``,
          subKey
        );
      });
      if (res.status === 200) {
        data = formAutoCompleteData("", res.data);
        yield put(setCompanys(data));
      } else {
        yield put(setCompanys([]));
      }

      yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}
function* get_Contact(requestData) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }
      let data;

      let url = getContact.url + `?typeText=` + requestData.contact;

      if (requestData.company !== "" && requestData.company !== undefined) {
        url = url + `&company=` + requestData.company;
      }

      let res = yield call(() => {
        return api(url, getContact.method, ``, subKey);
      });

      if (res.status === 200) {
        data = formAutoCompleteData("", res.data);
        yield put(setContacts(data));
      } else {
        yield put(setContacts([]));
      }
      yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}

function* get_StoreNumber(requestData) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }
      let data;

      let res = yield call(() => {
        return api(
          getStore.url + `?typeText=` + requestData.value,
          getStore.method,
          ``,
          subKey
        );
      });
      if (res.status === 200) {
        data = formAutoCompleteData("", res.data);
        yield put(setStoreNumber(data));
      } else {
        yield put(setStoreNumber([]));
      }

      yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}

function* get_OrderType() {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let res = yield call(() => {
        return api(getOrderType.url, getOrderType.method, ``, subKey);
      });
      let data;

      if (res.status === 200) {
        data = formAutoCompleteData("", res.data);
        yield put(setOrderType(data));
      } else {
        yield put(setOrderType([]));
      }

      // yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}

function* get_ProductSelection() {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }
      let res = yield call(() => {
        return api(
          getProductSelection.url,
          getProductSelection.method,
          ``,
          subKey
        );
      });
      if (res.status === 200) {
        let data = formSelectData("Select Product", res.data);
        yield put(setProductSelection(data));
      } else {
        yield put(setProductSelection([]));
      }

      // yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}

function* get_DivisionSelection() {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.key);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.subKey;
      }

      let res = yield call(() => {
        return api(getDivisions.url, getDivisions.method, ``, subKey);
      });
      if (res.status === 200) {
        let data = formSelectData("Select Division", res.data);
        yield put(setDivisionSelection(data));
        yield put(setDivision(res.data));
      } else {
        yield put(setDivisionSelection([]));
        yield put(setDivision([]));
      }

      // yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}

function* get_Role() {
  try {
    yield put(serviceInProgress(true));
    const data = userRole;
    yield put(setRole(data));
    yield put(serviceInProgress(false));
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}

function* get_SearchResult(searchObj) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));

      let url = getSearchResult.url;
      let reqBody = {
        orderStatus: searchObj.value.orderStatusVal,
        orderType: searchObj.value.orderTypeVal,
        companyName: searchObj.value.company,
        contactName: searchObj.value.contact,
        orderNumber: searchObj.value.orderNumber,
        serialNumber: searchObj.value.serialNumber,
        storeNumber: searchObj.value.storeNumber,
        checkNumber: searchObj.value.checkNumber,
        productId: searchObj.value.product,
        divisionName: searchObj.value.division,
        contactPhnNumber: searchObj.value.phoneNumber,
        orderFrom: searchObj.value.orderFromDt,
        orderTo: searchObj.value.orderToDt,
        activationFrom: searchObj.value.activationFromDt,
        activationTo: searchObj.value.activationToDt,
        pageNumber: searchObj.pageNo,
        sizePage: searchObj.rowCount,
        // "sortBy": searchObj.sortBy,
        // "sortField": searchObj.fieldName
      };
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let resp = yield call(() => {
        return api(url, getSearchResult.method, reqBody, subKey);
      });
      if (resp.status === 200) {
        yield put(serviceInProgress(false));
        yield put(setSearchComplete(1));
        yield put(setSearchResult(resp.data));
      } else {
        yield put(setSearchComplete(0));

        yield put(serviceInProgress(false));
        yield put(setSearchResult([]));
      }
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}
function* get_OrderDetail(requestParam) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let productIds;
      let url = getOrderDetail.url + `/${requestParam.value}`;

      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let resp = yield call(() => {
        return api(url, getOrderDetail.method, ``, subKey);
      });
      if (resp.status === 200) {
        yield put(setOrderDetail(resp.data));
        productIds = getProductId(
          resp.data?.orderProductCostDetails?.productDetails,
          requestParam.items
        );
        yield put(setSelectedProductItem(productIds));
        // if (requestParam.items === "") {
          
        // }
      } else {
        yield put(setOrderDetail(""));
      }

      yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}
function* get_OrderAditionalInfo(requestParam) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));

      let url = getOrderAditionalInfo.url + `/${requestParam.value}`;

      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let resp = yield call(() => {
        return api(url, getOrderAditionalInfo.method, ``, subKey);
      });
      if (resp.status === 200) {
        yield put(setOrderAditionalInfo(resp.data));
      } else {
        yield put(setOrderAditionalInfo(""));
      }

      yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}
function* get_FulFillMent(requestParam) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));

      let url =
        getFulFillMent.url + "/" + requestParam.value + `/start/fulfillment`;

      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let resp = yield call(() => {
        return api(url, getFulFillMent.method, ``, subKey);
      });

      if (resp.status === 200) {
        yield put(
          setFullFillMent(`This order is currently locked by you. Please complete the
        fulfillment activity and click on 'Save' to unlock the order
        for other users. Please click on the 'Unlock Order' Link
        to unlock/release the order at any point of time during
        fulfillment process.`)
        );
        yield put(setOrderLockedBy(1));
        yield put(setFullFillMentStatus(1));
      } else {
        yield put(setFullFillMent(""));
        yield put(setOrderLockedBy(0));
        yield put(setFullFillMentStatus(0));
      }

      yield put(serviceInProgress(false));
    }
  } catch (err) {
    let message = "";
    switch (err && err.response.status) {
      case 423:
        message = err.response.data.message;
        yield put(setFullFillMent(message));
        yield put(setOrderLockedBy(2));
        break;

      default:
        yield put(serviceError(exceptionFormatter(err)));
        yield put(setOrderLockedBy(0));
        break;
    }

    yield put(serviceInProgress(false));
  }
}

function* get_UnlockOrder(requestParam) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));

      let url = getUnlockOrder.url + `/` + requestParam.value + `/unlock`;

      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let resp = yield call(() => {
        return api(url, getUnlockOrder.method, ``, subKey);
      });

      if (resp.status === 200) {
        yield put(
          setSuccessMessage(`The lock on the order has now been released`)
        );
        yield put(setSuccessMessageType(1));
        yield put(setUnlockOrder(requestParam.value));
      } else {
        yield put(setSuccessMessage(""));
        yield put(setSuccessMessageType(""));
        yield put(setUnlockOrder(0));
      }

      yield put(serviceInProgress(false));
    }
  } catch (err) {
    switch (err && err.response.status) {
      // case 423:
      //   message = err.response.data.message;
      //   yield put(setFullFillMent(message));
      //   yield put(setOrderLockedBy(2));
      //   break;

      default:
        yield put(serviceError(exceptionFormatter(err)));
        yield put(setUnlockOrder(0));
        yield put(setSuccessMessage(""));
        yield put(setSuccessMessageType(""));
        break;
    }

    yield put(serviceInProgress(false));
  }
}

function* get_ExportToExcel(searchObj) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let url;
      let method;
      let FileName;
      let reqBody;
      if (searchObj.exportType === "orderList") {
        url = exportToExcelOrderList.url;
        method = exportToExcelOrderList.method;
        FileName = "Order List.xlsx";
        reqBody = {
          orderStatus: searchObj.value.orderStatusVal,
          orderType: searchObj.value.orderTypeVal,
          companyName: searchObj.value.company,
          contactName: searchObj.value.contact,
          orderNumber: searchObj.value.orderNumber,
          serialNumber: searchObj.value.serialNumber,
          storeNumber: searchObj.value.storeNumber,
          checkNumber: searchObj.value.checkNumber,
          productName: searchObj.value.product,
          divisionName: searchObj.value.division,
          contactPhnNumber: searchObj.value.phoneNumber,
          orderFrom: searchObj.value.orderFromDt,
          orderTo: searchObj.value.orderToDt,
          activationFrom: searchObj.value.activationFromDt,
          activationTo: searchObj.value.activationToDt,
          pageNumber: searchObj.pageNo,
          sizePage: searchObj.rowCount,
          // "sortBy": searchObj.sortBy,
          // "sortField": searchObj.fieldName
        };
      } else if (searchObj.exportType === "Activated") {
        url = exportToExcelActivated.url;
        method = exportToExcelActivated.method;
        FileName = "Activated Orders.xlsx";
        reqBody = {
          orderStatus: [],
          orderType: [],
          companyName: "",
          contactName: "",
          orderNumber: [],
          serialNumber: "",
          storeNumber: "",
          checkNumber: "",
          productName: "",
          divisionName: "",
          contactPhnNumber: "",
          orderFrom: "",
          orderTo: "",
          activationFrom: searchObj.value.activationFromDt,
          activationTo: searchObj.value.activationToDt,
          pageNumber: searchObj.pageNo,
          sizePage: searchObj.rowCount,
          // "sortBy": searchObj.sortBy,
          // "sortField": searchObj.fieldName
        };
      } else {
        url = exportToExcelActivatedForJournal.url;
        method = exportToExcelActivatedForJournal.method;
        FileName = "Activation for Journal List.xlsx";
        reqBody = {
          orderType: searchObj.value.orderTypeVal,
          companyName: searchObj.value.company,
          contactName: searchObj.value.contact,
          orderNumber: searchObj.value.orderNumber,
          serialNumber: searchObj.value.serialNumber,
          storeNumber: searchObj.value.storeNumber,
          checkNumber: searchObj.value.checkNumber,
          productName: searchObj.value.product,
          divisionName: searchObj.value.division,
          contactPhnNumber: searchObj.value.phoneNumber,
          orderFrom: searchObj.value.orderFromDt,
          orderTo: searchObj.value.orderToDt,
          activationFrom: searchObj.value.activationFromDt,
          activationTo: searchObj.value.activationToDt,
          pageNumber: searchObj.pageNo,
          sizePage: searchObj.rowCount,
          // "sortBy": searchObj.sortBy,
          // "sortField": searchObj.fieldName
        };
        if (
          searchObj.value.orderStatusVal !== undefined &&
          searchObj.value.orderStatusVal !== ""
        ) {
          reqBody.orderStatus = searchObj.value.orderStatusVal;
        } else {
          reqBody.orderStatus = getOrderStatusList(
            searchObj?.orderStatusOptions,
            "Select All"
          );
        }
      }

      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let dataTest = yield call(() => {
        return exportApi(url, method, reqBody, subKey);
      });

      if (dataTest) {
        var blob = new Blob([dataTest.data], { type: "application/xlsx" });
        var link = document.createElement("a");
        var fileURL = window.URL.createObjectURL(blob);
        link.href = fileURL;
        link.download = FileName;
        link.target = "_blank";
        link.click();
        //  return true;
      }
      yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}

function* get_PrintPackingSlip(requestParam) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let url = printPackingSlipUri.url;

      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let productData = [];

      requestParam?.value?.selectedProductItems?.map((pData) => {
        let productObj = {};
        let filterArr = [];
        productObj.productId = pData.productId;

        filterArr =
          requestParam?.value?.currentOrder?.orderProductCostDetails?.productDetails.filter(
            (filterData) => filterData.orderProductId === pData.orderProductId
          );

        productObj.denomination = filterArr[0].denomination;
        // productObj.productTotal = filterArr[0].totalPrice;
        productObj.productType = filterArr[0].productType;
        productObj.productUnitPrice = filterArr[0].productUnitPrice;
        productObj.orderProductId = filterArr[0].orderProductId;

        if (pData?.items?.length > 0) {
          pData?.items.map((serialData) => {
            let itemObj = {};
            let serialArr = serialData.serialRange.split("-");
            itemObj.cardStartingSeries = serialArr[0];
            itemObj.cardLastSeries = serialArr[1];
            itemObj.productTotal =
              serialData.totalQty * filterArr[0].productUnitPrice;
            itemObj.qty = serialData.totalQty;

            let finalObj = { ...itemObj, ...productObj };
            return productData.push(finalObj);
          });
        } else {
          let itemObj = {};
          itemObj.cardStartingSeries = "";
          itemObj.cardLastSeries = "";
          itemObj.qty = filterArr[0].productQty;

          let finalObj = { ...itemObj, ...productObj };
          return productData.push(finalObj);
        }

        return productData;
      });
      const addressConst = requestParam.value.currentOrder.contactAddress
        .addressLine
        ? requestParam.value.currentOrder.contactAddress.shippingStreet +
          ",\n" +
          requestParam.value.currentOrder.contactAddress.addressLine +
          ",\n" +
          requestParam.value.currentOrder.contactAddress.city +
          ", " +
          requestParam.value.currentOrder.contactAddress.state +
          ", " +
          requestParam.value.currentOrder.contactAddress.zipCode
        : requestParam.value.currentOrder.contactAddress.shippingStreet +
          ", " +
          requestParam.value.currentOrder.contactAddress.city +
          requestParam.value.currentOrder.contactAddress.addressLine +
          ", " +
          requestParam.value.currentOrder.contactAddress.state +
          ", " +
          requestParam.value.currentOrder.contactAddress.zipCode;
      /*const totalVal =
        parseFloat(
          requestParam.value.currentOrder.orderProductCostDetails.overalTotal
        ) +
        parseFloat(
          requestParam.value.currentOrder.orderProductCostDetails.shippingCost
        ) -
        parseFloat(
          requestParam.value.currentOrder.orderProductCostDetails.discountTotal
        );*/
      let formattedDate = moment(new Date()).format("MM/DD/YYYY");
      let reqBody = {
        orderId: requestParam.value.currentOrder.orderId,
        orderNumber: requestParam.value.currentOrder.orderNumber,
        // trackingNumber: requestParam.value.trackingNumber, //?? will be entered by user ??
        orderType: requestParam.value.currentOrder.orderType,
        name: requestParam.value.currentOrder.contactName,
        phoneNumber: requestParam.value.currentOrder.contactPhoneNumber,
        compnayName: requestParam.value.currentOrder.organizationName,
        address: addressConst,
        storeAddress: requestParam.value.gcData.selectedOrder.storeAddress,
        orderStatus: requestParam.value.gcData.selectedOrder.status,
        orderDate:
          requestParam.value.selectedOrderAditionalInfo?.orderPlacedByDateTime?.split(
            " "
          )[0],
        total:
          requestParam.value.currentOrder.orderProductCostDetails.overalTotal,
        totalValue:
          requestParam.value.currentOrder.orderProductCostDetails
            .orderNumberUpdatedDtls.totalCardValue,
        shippingValue:
          requestParam.value.currentOrder.orderProductCostDetails.shippingCost, // field under review
        discount:
          requestParam.value.currentOrder.orderProductCostDetails.discountTotal,
        banner: requestParam.value.currentOrder.banner,
        store: requestParam.value.currentOrder.store, //check this field once "0177" is the value from props but "Bangalore" in dummy resp

        shippingDate: formattedDate, //field under review - pass todays date MM/dd/yyyy
        shippingMthod: requestParam.value.currentOrder.shippingType, //"UPS",
        totalCards:
          requestParam.value.currentOrder.orderProductCostDetails.totalCards,
        division: requestParam.value.currentOrder.division, //"Norcal",
        productDetails: productData,
      };

      let resp = yield call(() => {
        return api(url, printPackingSlipUri.method, reqBody, subKey);
      });

      let FileName =
        "PackingSlip_Order#" + requestParam.value.currentOrder.orderNumber;

      if (resp) {
        const decodedData = atob(resp.data);
        const byteArray = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; i++) {
          byteArray[i] = decodedData.charCodeAt(i);
        }
        var blob = new Blob([byteArray], { type: "application/pdf" });
        var link = document.createElement("a");
        var fileURL = window.URL.createObjectURL(blob);
        link.href = fileURL;
        link.download = FileName;
        link.target = "_blank";
        link.click();
      }
      yield put(
        setPrintSlipMessage(`Packing slip is now downloaded successfully`)
      );
      yield put(serviceInProgress(false));
    }
  } catch (exp) {
    // yield put(
    //   setFullFillMent(
    //     `The lock on the order has now been released". "Shipping information added successfully. Now the order status has changed to 'Shipped'`
    //   )
    // );
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}

function* set_trackDataReceivedFromSave(requestParam) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));

      let url = saveShippingInfoUri.url;

      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }
      let productData = [];

      requestParam?.value?.selectedProductItems?.map((pData) => {
        let productObj = {};
        let filterArr = [];
        productObj.productId = pData.productId;

        filterArr =
          requestParam?.value?.currentOrder?.orderProductCostDetails?.productDetails.filter(
            (filterData) => filterData.orderProductId === pData.orderProductId
          );

        productObj.denomination = filterArr[0].denomination;
        // productObj.productTotal = filterArr[0].totalPrice;
        productObj.productType = filterArr[0].productType;
        productObj.productUnitPrice = filterArr[0].productUnitPrice;
        productObj.orderProductId = filterArr[0].orderProductId;

        if (pData?.items?.length > 0) {
          pData?.items.map((serialData) => {
            let itemObj = {};
            let serialArr = serialData.serialRange.split("-");
            itemObj.cardStartingSeries = serialArr[0];
            itemObj.cardLastSeries = serialArr[1];
            itemObj.productTotal =
              serialData.totalQty * filterArr[0].productUnitPrice;
            itemObj.qty = serialData.totalQty;

            let finalObj = { ...itemObj, ...productObj };
            return productData.push(finalObj);
          });
        } else {
          let itemObj = {};
          itemObj.cardStartingSeries = "";
          itemObj.cardLastSeries = "";
          itemObj.qty = filterArr[0].productQty;

          let finalObj = { ...itemObj, ...productObj };
          return productData.push(finalObj);
        }

        return productData;
      });
      const addressConst = requestParam.value.currentOrder.contactAddress
        .addressLine
        ? requestParam.value.currentOrder.contactAddress.shippingStreet +
          ",\n" +
          requestParam.value.currentOrder.contactAddress.addressLine +
          ",\n" +
          requestParam.value.currentOrder.contactAddress.city +
          ", " +
          requestParam.value.currentOrder.contactAddress.state +
          ", " +
          requestParam.value.currentOrder.contactAddress.zipCode
        : requestParam.value.currentOrder.contactAddress.shippingStreet +
          ", " +
          requestParam.value.currentOrder.contactAddress.city +
          requestParam.value.currentOrder.contactAddress.addressLine +
          ", " +
          requestParam.value.currentOrder.contactAddress.state +
          ", " +
          requestParam.value.currentOrder.contactAddress.zipCode;
      /*const totalVal =
        parseFloat(
          requestParam.value.currentOrder.orderProductCostDetails.overalTotal
        ) +
        parseFloat(
          requestParam.value.currentOrder.orderProductCostDetails.shippingCost
        ) -
        parseFloat(
          requestParam.value.currentOrder.orderProductCostDetails.discountTotal
        );*/
      let formattedDate = moment(new Date()).format("MM/DD/YYYY");
      let reqBody = {
        orderId: requestParam.value.currentOrder.orderId,
        orderNumber: requestParam.value.currentOrder.orderNumber,
        trackingNumber: requestParam?.value?.trackingNumber, //?? will be entered by user ??
        orderType: requestParam.value.currentOrder.orderType,
        name: requestParam.value.currentOrder.contactName,
        phoneNumber: requestParam.value.currentOrder.contactPhoneNumber,
        compnayName: requestParam.value.currentOrder.organizationName,
        address: addressConst,
        storeAddress: requestParam.value.gcData.selectedOrder.storeAddress,
        orderStatus: requestParam.value.gcData.selectedOrder.status,
        orderDate:
          requestParam.value.selectedOrderAditionalInfo?.orderPlacedByDateTime?.split(
            " "
          )[0],
        total:
          requestParam.value.currentOrder.orderProductCostDetails.overalTotal,
        totalValue:
          requestParam.value.currentOrder.orderProductCostDetails
            .orderNumberUpdatedDtls.totalCardValue,
        shippingValue:
          requestParam.value.currentOrder.orderProductCostDetails.shippingCost, // field under review
        discount:
          requestParam.value.currentOrder.orderProductCostDetails.discountTotal,
        banner: requestParam.value.currentOrder.banner,
        store: requestParam.value.currentOrder.store, //check this field once "0177" is the value from props but "Bangalore" in dummy resp

        shippingDate: formattedDate, //field under review - pass todays date MM/dd/yyyy
        shippingMthod: requestParam.value.currentOrder.shippingType, //"UPS",
        totalCards:
          requestParam.value.currentOrder.orderProductCostDetails.totalCards,
        division: requestParam.value.currentOrder.division, //"Norcal",
        productDetails: productData,
      };

      let resp = yield call(() => {
        return api(url, saveShippingInfoUri.method, reqBody, subKey);
      });

      if (resp.status === 200) {
        //chaning to new comment as per UAT defect
        yield put(setFullFillMentCompleted(1));
        yield put(setFullFillMentStatus(0));
        yield put(setOrderLockedBy(0)); //this unlocks the order : check with Rahul
        // yield put(setFullFillMent(""))
        yield put(setSaveBtnStatus(true)); //disable save button once call completes
      } else {
        yield put(setFullFillMent(""));
        yield put(setOrderLockedBy(1)); //keep the order locked if save was not successfull
      }

      yield put(serviceInProgress(false));
    }
  } catch (err) {
    let message = "";
    switch (err && err?.response?.status) {
      case 423:
        message = err.response.data.message;
        yield put(setFullFillMent(message));
        yield put(setOrderLockedBy(2)); //locked by another user
        break;

      case 500:
        message = err.response.data.message;
        yield put(setFullFillMent(""));
        yield put(setWarningMessage(message));
        yield put(setWarningPosition("fulfillMent"));
        yield put(setOrderLockedBy(0)); //locked by another user
        break;

      default:
        yield put(serviceError(exceptionFormatter(err)));
        yield put(setOrderLockedBy(0));
        break;
    }

    yield put(serviceInProgress(false));
  }
}
function* get_Card(requestParam) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let resp = yield call(() => {
        return api(getCard.url, getCard.method, requestParam.value, subKey);
      });

      if (resp.status === 200) {
        yield put(setFullFillMent(""));
        yield put(setOrderLockedBy(0));
        updateProductItems(
          resp.data,
          requestParam.value.firstCardInSeries,
          requestParam.productData,
          "update"
        );
      } else {
        yield put(setFullFillMent(""));
        yield put(setOrderLockedBy(0));
      }

      yield put(serviceInProgress(false));
    }
  } catch (err) {
    let message = "";
    switch (err && err.response.status) {
      case 423:
        message = err.response.data.message;
        yield put(setFullFillMent(message));
        yield put(setOrderLockedBy(2));
        break;
      case 400:
        message = err.response.data.message;
        yield put(setFullFillMent(message));
        yield put(setOrderLockedBy(1));
        break;
      default:
        yield put(serviceError(exceptionFormatter(err)));
        yield put(setOrderLockedBy(0));
        break;
    }

    yield put(serviceInProgress(false));
  }
}

//to Upload files
function* set_FileAttached(fileName) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);
      if (subKey === undefined) {
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.subKey;
      }
      //create a request body to send here
      let reqBody = {
        fileName: fileName.fileName,
        fileNameWithExtension: fileName.fileNameWithExtension,
        fileContentLocation: fileName.fileContentLocation,
        salesforceEncryptedOrderId: fileName.salesforceEncryptedOrderId,
        fileBase64Data: fileName.fileBase64Data,
        svwpUserId: fileName.svwpUserId,
        svwpUserName: fileName.svwpUserName,
      };
      let data = yield call(() => {
        return exportApi(
          `${setFileAttachd.url}`,
          setFileAttachd.method,
          reqBody,
          subKey
        );
      });
      //
      // console.log("response from set_FileAttached", data);
      if (data.status === 200) {
        // yield put(setFileAttached(data.data));
        // yield put(setFilesToDisplay(data.data));
        // console.log("getFileAttached call 9")
        yield put(getFileAttached(fileName.salesforceEncryptedOrderId));
        yield put(serviceInProgress(false));
      } else {
        //set error message here
      }
    }
  } catch (exp) {
    if (exp && exp.response.status === 400) {
      //set error message here
      // yield put(setFileUploadErrMsg(exp.response.data.message));
    } else {
      //set error message here
      yield put(serviceError(exceptionFormatter(exp)));
    }
    yield put(serviceInProgress(false));
  }
}
//retreive list of files uploaded
function* get_FileAttached(requestParam) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);
      if (subKey === undefined) {
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.subKey;
      }
      // console.log("getFileAttachd.url", getFileAttachd.url);
      // console.log("getFileAttachd.method", getFileAttachd.method);
      let data = yield call(() => {
        return api(
          getFileAttachd.url + `/` + requestParam.value,
          getFileAttachd.method,
          ``,
          subKey
        );
      });

      // yield put(setFileAttached(data.data));
      yield put(setFilesToDisplay(data.data));
      yield put(serviceInProgress(false));
    }
  } catch (exp) {
    if (exp && exp.response.status === 400) {
      // yield put(setFileUploadErrMsg(exp.response.data.message));
    } else {
      yield put(serviceError(exceptionFormatter(exp)));
    }
    yield put(serviceInProgress(false));
  }
}

function* downloadFile(file) {
  try {
    // console.log("inside download file", file);
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let data = yield call(() => {
        return api(
          downloadFileAttachd.url + `/` + file?.value.fileId,
          downloadFileAttachd.method,
          ``,
          subKey
        );
      });

      let FileName = `${file?.value.fileName}`;
      let fileType = file?.value.fileType;
      const fileTypeExt = fileType !== "UNKNOWN" ? fileType.toLowerCase() : "";
      FileName = FileName + "." + fileTypeExt;

      // const receivedFileType = file?.value.fileName; // Replace with the file type received from the request
      const mimeType = convertFileTypeToMimeType(fileType);

      // downloadFileUtitlity(data.data,FileName,fileType)
      //to download file using base64 data
      let base64Data = data.data; //extract the base64Data here from response
      if (data) {
        const decodedData = atob(base64Data);
        const byteArray = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; i++) {
          byteArray[i] = decodedData.charCodeAt(i);
        }
        // var blob = new Blob([resp.data], { type: "application/pdf" });
        var blob = new Blob([byteArray], { type: `${mimeType}` });
        var link = document.createElement("a");
        var fileURL = window.URL.createObjectURL(blob);
        link.href = fileURL;
        link.download = FileName;
        link.target = "_blank";
        link.click();
        //  return true;
      }

      yield put(serviceInProgress(false));
      yield put(setFileMessage("File downloaded successfully"));
      yield put(setFileUploadErrorMsgColor("green"));
    }
  } catch (exp) {
    console.log("Download file failed for fileId: ", file?.fileId);

    if (
      exp &&
      exp.response &&
      (exp.response.status === 400 || exp.response.status === 500)
    ) {
      // Handle 400 status
      yield put(
        setFileMessage("File downloaded unsuccessfull.Please try again")
      );
      yield put(setFileUploadErrorMsgColor("red"));
      yield put(serviceInProgress(false));
    } else {
      // Handle other errors
      yield put(
        setFileMessage("File downloaded unsuccessfull.Please try again")
      );
      yield put(setFileUploadErrorMsgColor("red"));
      yield put(serviceInProgress(false));
    }
  }
}

// Define a mapping of file extensions to MIME types
const fileExtensionToMimeTypeMap = {
  // Add more mappings as needed
  outlook: "application/vnd.ms-outlook",
  msg: "application/vnd.ms-outlook",
  pst: "application/vnd.ms-outlook",
  eml: "message/rfc822",
  oft: "application/vnd.ms-outlooktemplate",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  txt: "text/plain",
  pdf: "application/pdf",
  xls: "application/vnd.ms-excel",
  // xlsx:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // xlsx:"application/xlsx",
  // excel_x:"application/xlsx",
  excel_x: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  word_x:
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  power_point_x:
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  unknown: "application/vnd.sealed.eml",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  bmp: "image/bmp",
  png: "image/png",
};

// Function to convert file types to MIME types
const convertFileTypeToMimeType = (fileType) => {
  // Normalize the file type received from the request (e.g., to lowercase)
  const normalizedFileType = fileType.toLowerCase();
  // Look up the MIME type from the mapping
  const mimeType = fileExtensionToMimeTypeMap[normalizedFileType];
  // Return the corresponding MIME type if found, or a default value if not found
  // return mimeType || "application/octet-stream"; // Use "application/octet-stream" for unknown file types
  return mimeType;
};

// const downloadFileUtitlity = (base64Data, fileName, fileType) => {
//   // Convert base64 to Blob
//   const byteCharacters = atob(base64Data.split(",")[1]);
//   const byteArrays = [];
//   for (let offset = 0; offset < byteCharacters.length; offset += 512) {
//     const slice = byteCharacters.slice(offset, offset + 512);

//     const byteNumbers = new Array(slice.length);

//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }

//     const byteArray = new Uint8Array(byteNumbers);

//     byteArrays.push(byteArray);
//   }

//   const blob = new Blob(byteArrays, { type: fileType });

//   // Create a download link
//   const downloadLink = document.createElement("a");
//   downloadLink.href = URL.createObjectURL(blob);
//   downloadLink.download = fileName;
//   // Trigger the download
//   downloadLink.click();
// };

function* deleteFile(file) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      //fileId /ldapid
      const resp = yield call(() =>
        api(
          deleteFileAttachd.url +
            "/" +
            file?.value.file.fileId +
            "/" +
            file?.value.ldapId,
          deleteFileAttachd.method,
          ``,
          subKey
        )
      );
      console.log("response from deleteFile", resp);
      // console.log("getFileAttached call 9")
      yield put(getFileAttached(file?.value.orderId));
      // yield put(setFileMessage(resp.data.message));
      yield put(setFileMessage("File has been deleted successfully"));
      yield put(setFileUploadErrorMsgColor("green"));
      // yield put(setFileAttached(data.data));
      yield put(serviceInProgress(false));
    }
  } catch (exp) {
    if (exp && exp.response && exp.response.status === 400) {
      // Handle 400 status
    } else {
      // Handle other errors
    }
    yield put(serviceInProgress(false));
  }
}

function* get_Orders(searchObj) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));

      let url = getOrders.url + "/" + searchObj.value;

      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let resp = yield call(() => {
        return api(url, getOrders.method, ``, subKey);
      });

      if (resp.status === 200) {
        yield put(serviceInProgress(false));
        yield put(setOrders(resp.data));
      } else if (resp.status === 204) {
        yield put(serviceInProgress(false));
        yield put(setOrders("invalid"));
      } else {
        yield put(serviceInProgress(false));
        yield put(setOrders([]));
      }
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}

function* get_CommentAction(requestObj) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let url = getComment.url;
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let resp = yield call(() => {
        return api(url, getComment.method, requestObj?.value, subKey);
      });
      if (resp.status === 200) {
        yield put(setCommentPosted(1));
        // yield put(setOrders(resp.data));
        yield put(setCommentAction(resp.data));
      } else {
        yield put(setCommentPosted(0));
        yield put(setCommentAction([]));
      }

      yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}

function* fetch_Comments(orderId) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      const resp = yield call(() =>
        api(
          fetchCommentsUri.url + "/" + orderId?.value,
          fetchCommentsUri.method,
          ``,
          subKey
        )
      );
      // console.log("response from fetch_Comments", resp);
      // yield put(setFetchedComments(resp.data)); //this should populate fetched comments value
      // yield put(serviceInProgress(false));
      if (resp.status === 200) {
        yield put(setCommentsData(resp.data)); //this should populate fetched comments value
        yield put(serviceInProgress(false));
      } else {
        yield put(setCommentsData([]));
      }
    }
  } catch (exp) {
    if (exp && exp.response && exp.response.status === 400) {
      // Handle 400 status
    } else {
      // Handle other errors
    }
    yield put(serviceInProgress(false));
  }
}

function* post_Comment(comment) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }
      const reqBody = {
        comments: comment.value.comments,
        orderId: comment.value.orderId,
        commentType: comment.value.commentType,
        svwpCommentUserId: comment.value.svwpCommentUserId,
        svwpCommentUserName: comment.value.svwpCommentUserName,
      };
      // console.log("setPostCommHistUri", setPostCommHistUri);
      let resp = yield call(() => {
        return api(
          setPostCommHistUri.url,
          setPostCommHistUri.method,
          reqBody,
          subKey
        );
      });

      // console.log("response from post_Comments", resp);
      // yield put(setFetchedComments(resp.data)); //this should populate fetched comments value
      // yield put(serviceInProgress(false));
      if (resp.status === 200) {
        // console.log("setFetchedComments 4")
        yield put(setFetchedComments(comment.value.orderId)); //this should populate fetched comments value depending if response returns any data
        yield put(serviceInProgress(false));
      } else {
        // yield put(setFetchedComments([]));
      }
    }
  } catch (exp) {
    if (exp && exp.response && exp.response.status === 400) {
      // Handle 400 status
    } else {
      // Handle other errors
    }
    yield put(serviceInProgress(false));
  }
}

function* delete_Comment(data) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      const resp = yield call(() =>
        api(
          deletePostCommHistUri.url +
            "/" +
            data?.value.commentId +
            "/" +
            data?.value.ldapId,
          deletePostCommHistUri.method,
          ``,
          subKey
        )
      );
      // console.log("response from delete comment", resp);
      if (resp.status === 200) {
        // console.log("setFetchedComments 5")
        yield put(setFetchedComments(data.value.orderId)); //this should populate fetched comments value depending if response returns any data
        yield put(serviceInProgress(false));
      } else {
        // yield put(setFetchedComments([]));
      }
    }
  } catch (exp) {
    if (exp && exp.response && exp.response.status === 400) {
      // Handle 400 status
    } else {
      // Handle other errors
    }
    yield put(serviceInProgress(false));
  }
}

function* edit_Comment(data) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let reqBody = {
        comments: data.value.comments,
        orderId: data.value.orderId,
        commentType: data.value.commentType,
        commentId: data.value.commentId,
        svwpCommentUserId: data.value.svwpCommentUserId,
        svwpCommentUserName: data.value.svwpCommentUserName,
      };

      const resp = yield call(() =>
        api(editCommUri.url, editCommUri.method, reqBody, subKey)
      );
      // console.log("response from delete comment", resp);
      if (resp.status === 200) {
        // console.log("setFetchedComments 6")
        yield put(setFetchedComments(data.value.orderId)); //this should populate fetched comments value depending if response returns any data
        yield put(serviceInProgress(false));
      } else {
        // yield put(setFetchedComments([]));
      }
    }
  } catch (exp) {
    if (exp && exp.response && exp.response.status === 400) {
      // Handle 400 status
    } else {
      // Handle other errors
    }
    yield put(serviceInProgress(false));
  }
}

function* get_ActivationInfo(orderId) {
  try {
    yield put(serviceInProgress(true));
    // console.log("Inside get_ActivationInfo : ", orderId);
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }
      // http://localhost:8088/bogc/activationinformation/?orderId=80103000002NegvAAC
      const resp = yield call(() =>
        api(
          activationinformationUri.url + "?orderId=" + orderId?.value,
          activationinformationUri.method,
          ``,
          subKey
        )
      );
      // console.log("response from get_ActivationInfo", resp);
      // yield put(setFetchedComments(resp.data)); //this should populate fetched comments value
      // yield put(serviceInProgress(false));
      if (resp.status === 200) {
        yield put(setActivationInfo(resp.data)); //this should populate fetched comments value
        yield put(serviceInProgress(false));
      } else {
        yield put(setActivationInfo(""));
        yield put(serviceInProgress(false));
      }
    }
  } catch (exp) {
    if (exp && exp.response && exp.response.status === 400) {
      // Handle 400 status
    } else {
      // Handle other errors
    }
    yield put(serviceInProgress(false));
  }
}

function* submit_Devaluation(data) {
  try {
    // console.log("Inside submit_Devaluation : ", data);
    if (isJWTNotExpired()) {
      // yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      const reqBody = {
        deactivationReq: data.value.deactivationReq,
        orderId: data.value.orderId,
        orderNumber: data.value.orderNumber,
        productName: data.value.productName,
      };

      // let status = "Devaluation in Progress";
      // switch (resp.data) {
      //   case "Failure Devaluation":
      //     yield put(setDevaluateModalTitle("FailedDeactivationModal"));
      //     yield put(setDevaluateBtnTitle("Failure Devaluation"));
      //     yield put(setCheckStatusBool(false));
      //     break;
      //   case "Success Devaluation":
      //     yield put(setDevaluateModalTitle("SuccessfullDeactivationModal"));
      //     yield put(setDevaluateBtnTitle("Success Devaluation"));
      //     yield put(setCheckStatusBool(false));
      //     break;
      //   case "Partial Devaluation":
      //     yield put(setDevaluateModalTitle("PartialDeactivationModal"));
      //     yield put(setDevaluateBtnTitle("Partial Devaluation"));
      //     yield put(setCheckStatusBool(false));
      //     break;
      //     case "Devaluation in Progress":
      //     yield put(setDevaluateModalTitle("DevaluationProgress"));
      //     yield put(setDevaluateBtnTitle("Devaluation in Progress"));
      //     yield put(setCheckStatusBool(false));
      //     break;
      // }

      yield put(setDevaluateModalTitle("DevaluationProgress"));
      yield put(
        setDevaluateBtnTitle(
          "Devaluation in Progress",
          data.value.devaluateBtnTitleIndex
        )
      );
      yield put(setCheckStatusBool(false));
      let resp = yield call(() => {
        return apiDeval(
          submitDevaluationUri.url,
          submitDevaluationUri.method,
          reqBody,
          subKey
        );
      });
      // let resp={};
      // resp.data="Failure Devaluation";
      // resp.status=200;
      //while no response do the following :
      // console.log("response from submit_Devaluation", resp);
      /*
      status="Failure Devaluation";
      status="Success Devaluation";
      status="Partial Devaluation"; 
      */
      //////////////////////////////////
      //call activationInfo api again
      ////////////////////////////////
      if (resp.status === 200) {
        switch (resp.data) {
          case "Failed Devaluation":
            yield put(setDevaluateModalTitle("FailedDeactivationModal"));
            yield put(
              setDevaluateBtnTitle(
                "Failed Devaluation",
                data.value.devaluateBtnTitleIndex
              )
            );
            yield put(setCheckStatusBool(false));
            break;
          case "Success Devaluation":
            yield put(setDevaluateModalTitle("SuccessfullDeactivationModal"));
            yield put(
              setDevaluateBtnTitle(
                "Successful Devaluation",
                data.value.devaluateBtnTitleIndex
              )
            );
            yield put(setCheckStatusBool(false));
            break;
          case "Partial Devaluation":
            yield put(setDevaluateModalTitle("PartialDeactivationModal"));
            yield put(
              setDevaluateBtnTitle(
                "Partial Devaluation",
                data.value.devaluateBtnTitleIndex
              )
            );
            yield put(setCheckStatusBool(false));
            break;
          case "Devaluation in Progress":
            yield put(setDevaluateModalTitle("DevaluationProgress"));
            yield put(
              setDevaluateBtnTitle(
                "Devaluation in Progress",
                data.value.devaluateBtnTitleIndex
              )
            );
            yield put(setCheckStatusBool(false));
            break;
          default:
            yield put(setCheckStatusBool(false));
            break;
        }

        // yield put(setDevaluateBtnStatus(false));
        // yield put(setDevaluateModalTitle(""));
        // yield put(serviceInProgress(false));
      } else {
        // yield put(setActivationInfo(""));
      }
    }
  } catch (exp) {
    if (exp && exp.response && exp.response.status === 423) {
      //This means the order is locked
      // yield put(setCheckStatusBool(true));
      yield put(setActivationOrderLockedMsg(exp.response.data.message));
      // yield put(setDevaluateModalTitle("DevaluationProgress"));
      // yield put(
      //   setDevaluateBtnTitle("Devaluate", data.value.devaluateBtnTitleIndex)
      // );
    } else {
      // Handle other errors
    }
    yield put(serviceInProgress(false));
  }
}

function* check_Status(data) {
  try {
    ////////////remove this line////////////////////
    // yield put(setDevaluateModalTitle("FailedDeactivationModal"));
    ////////////////////////////////////////////////
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }
      const reqBody = {
        devalueFromCard: data.value.devalueFromCard,
        devalueToCard: data.value.devalueToCard,
        status: data.value.status, //success,failure,
        unitValue: data.value.unitValue,
      };
      // const reqBody = {
      //   devalueFromCard: "16637177192,16637177250,16637177336",
      //   devalueToCard: "16637177216,16637177325,16637177435",
      //   status: "failure",
      //   unitValue: "100",
      // };
      // {"devalueFromCard":"16637177435","devalueToCard":"16637177192","status":"Failure","unitValue":"100"}
      // "Devalue_Card_Number_From__c": "16637177192,16637177250,16637177336",

      // "Devalue_Card_Number_To__c": "16637177216,16637177325,16637177435",
      let resp = yield call(() => {
        return api(checkStatusUri.url, checkStatusUri.method, reqBody, subKey);
      });

      /*
      status="Failure Devaluation";
      status="Success Devaluation";
      status="Partial Devaluation"; 
      */

      if (resp.status === 200) {
        // yield put(setDevaluateBtnStatus(false));
        // yield put(setDevaluateModalTitle(""));
        yield put(setDataFromCheckStatus(resp.data));
        // yield put(setCheckStatusFail(resp.data)); //set data for download excel
        yield put(serviceInProgress(false));
      } else {
        // yield put(setActivationInfo(""));
      }
    }
  } catch (exp) {
    if (exp && exp.response && exp.response.status === 400) {
      // Handle 400 status
    } else {
      // Handle other errors
    }
    yield put(serviceInProgress(false));
  }
}

function* download_Devaluation_Excel(data) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      const reqBody = {
        devalueFromCard: data.value.devalueFromCard,
        devalueToCard: data.value.devalueToCard,
        status: data.value.status, //success,failure,
        unitValue: data.value.unitValue,
      };
      let dataTest = yield call(() => {
        return exportApi(
          downloadDevaluationExcelUri.url,
          downloadDevaluationExcelUri.method,
          reqBody,
          subKey
        );
      });
      let FileName =
        data.value.status + "_Devaluation_#" + data.value.ordernumber + ".xlsx";
      if (dataTest) {
        // var blob = new Blob([dataTest.data], { type: "application/xlsx" });
        var blob = new Blob([dataTest.data], {
          type: "application/xlsx",
        });
        // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
        var link = document.createElement("a");
        var fileURL = window.URL.createObjectURL(blob);
        link.href = fileURL;
        link.download = FileName;
        link.target = "_blank";
        link.click();
        //  return true;
      }
      yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}

function* get_PrintInvoice(orderNumber) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));

      //       let url = printInvoiceUri.url;

      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let resp = yield call(() => {
        return api(
          printInvoiceUri.url + "/" + orderNumber?.value,
          printInvoiceUri.method,
          ``,
          subKey
        );
      });

      let FileName = "PackingInvoice_Order#" + orderNumber?.value;

      if (resp) {
        const decodedData = atob(resp.data);
        const byteArray = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; i++) {
          byteArray[i] = decodedData.charCodeAt(i);
        }
        var blob = new Blob([byteArray], { type: "application/pdf" });
        var link = document.createElement("a");
        var fileURL = window.URL.createObjectURL(blob);
        link.href = fileURL;
        link.download = FileName;
        link.target = "_blank";
        link.click();
      }
      yield put(serviceInProgress(false));
    }
  } catch (exp) {
    // yield put(
    //   setFullFillMent(
    //     `The lock on the order has now been released". "Shipping information added successfully. Now the order status has changed to 'Shipped'`
    //   )
    // );
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}

function* get_Order_Data(requestParam) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));

      let url = getOrderNumber.url + `/${requestParam.value}`;

      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      // let resp = {
      //   orderid: "6299",
      //   orderNbr: "606299",
      //   status: "Shipped",
      //   orderType: "Store Delivery",
      //   companyName: "TP Therapeutics ",
      //   fullName: "Justin Henry",
      //   orderDateTime: "11/26/18 15:52:58",
      //   orderPlacedBy: "SLIN112",
      // };
      // yield put(setOrderData(resp));
      // yield put(setMessage(`Invoice email sent successfully`));
      // yield put(setMessageType(`error`));

      let resp = yield call(() => {
        return api(url, getOrderNumber.method, ``, subKey);
      });
      if (resp.status === 200) {
        yield put(setOrderData(resp.data));
      } else {
        yield put(setOrderData({}));
      }

      yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}

function* get_PrintSlip(requestParam) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let url = getPrintSlip.url + "/" + requestParam.value;

      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let resp = yield call(() => {
        return api(url, getPrintSlip.method, ``, subKey);
      });

      if (resp.status === 200) {
        let FileName = "PackingSlip_Order#" + requestParam.value;

        if (resp) {
          const decodedData = atob(resp.data);
          const byteArray = new Uint8Array(decodedData.length);
          for (let i = 0; i < decodedData.length; i++) {
            byteArray[i] = decodedData.charCodeAt(i);
          }
          var blob = new Blob([byteArray], { type: "application/pdf" });
          var link = document.createElement("a");
          var fileURL = window.URL.createObjectURL(blob);
          link.href = fileURL;
          link.download = FileName;
          link.target = "_blank";
          link.click();
        }
        yield put(setMessage(`Packing Slip is now downloaded successfully`));
        yield put(setMessageType(`success`));
      }

      yield put(serviceInProgress(false));
    }
  } catch (err) {
    console.log("err: ", err.response);
    let message = "";
    switch (err && err.response.status) {
      case 406:
        message = err.response.data.message;
        yield put(setMessage(message));
        yield put(setMessageType(`error`));
        break;
      default:
        yield put(serviceError(exceptionFormatter(err)));
        break;
    }
    yield put(serviceInProgress(false));
  }
}

function* get_Invoice(requestParam) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let url = getInvoices.url + "/" + requestParam.value;

      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let resp = yield call(() => {
        return api(url, getInvoices.method, ``, subKey);
      });

      if (resp.status === 200) {
        let FileName = "Invoice_Order#" + requestParam.value;

        if (resp) {
          const decodedData = atob(resp.data);
          const byteArray = new Uint8Array(decodedData.length);
          for (let i = 0; i < decodedData.length; i++) {
            byteArray[i] = decodedData.charCodeAt(i);
          }
          var blob = new Blob([byteArray], { type: "application/pdf" });
          var link = document.createElement("a");
          var fileURL = window.URL.createObjectURL(blob);
          link.href = fileURL;
          link.download = FileName;
          link.target = "_blank";
          link.click();
        }
        yield put(setMessage(`Invoice is now downloaded successfully`));
        yield put(setMessageType(`success`));
      }

      yield put(serviceInProgress(false));
    }
  } catch (err) {
    let message = "";
    switch (err && err.response.status) {
      case 406:
        message = err.response.data.message;
        yield put(setMessage(message));
        yield put(setMessageType(`error`));
        break;
      default:
        yield put(serviceError(exceptionFormatter(err)));
        break;
    }
    yield put(serviceInProgress(false));
  }
}

function* send_Email(requestParam) {
  try {
    if (isJWTNotExpired()) {
      yield put(serviceInProgress(true));
      let url = sendEmails.url + "/" + requestParam.value;

      let subKey = yield select(selectors.bogcKey);

      if (subKey === undefined) {
        // JWTExpirationCheck();
        let response = yield call(() => {
          return nodeApi(getSubscriptionKey.url, getSubscriptionKey.method);
        });
        subKey = response && response.data && response.data.bogcSubKey;
      }

      let resp = yield call(() => {
        return api(url, sendEmails.method, ``, subKey);
      });

      if (resp.status === 200) {
        yield put(setMessage(`Invoice email sent successfully`));
        yield put(setMessageType(`success`));
      }

      yield put(serviceInProgress(false));
    }
  } catch (exp) {
    yield put(serviceInProgress(false));
    yield put(serviceError(exceptionFormatter(exp)));
  }
}

// function* handleTokenRefresh(action) {
//   try {
//     if (isJWTNotExpired()) {
//       let { code } = authTokenCookie();
//       const resp = yield call(() =>
//         nodeApi(refreshTokenUri.url, refreshTokenUri.method)
//       );

//       console.log("Token data", resp);
//       // if (resp.status === 200) {
//       //   setRefreshAuthToken(resp.data);
//       // } else {
//       //   setRefreshAuthToken("");
//       // }
//     }
//   } catch (exp) {
//     console.log("Error Token data", exp);
//     if (exp && exp.response && exp.response.status === 400) {
//       // Handle 400 status
//     } else {
//       // Handle other errors
//     }
//     yield put(serviceInProgress(false));
//   }
// }

function* Watcher() {
  yield takeLatest(GET_APIMKEY, get_SubKey);
  yield takeLatest(GET_ROLE, get_Role);
  yield takeLatest(GET_ACCESSDETAILS, get_AccessDetails);
  yield takeLatest(GET_ORDER_STATUS, get_OrderStatus);
  yield takeLatest(GET_COMPANY, get_Company);
  yield takeLatest(GET_CONTACT, get_Contact);
  yield takeLatest(GET_STORENUMBER, get_StoreNumber);
  yield takeLatest(GET_ORDER_TYPE, get_OrderType);
  yield takeLatest(GET_PRODUCT_SELECTION, get_ProductSelection);
  yield takeLatest(GET_DIVISION_SELECTION, get_DivisionSelection);
  yield takeLatest(GET_SEARCHRESULT, get_SearchResult);
  yield takeLatest(GET_ORDER_DETAIL, get_OrderDetail);
  yield takeLatest(GET_EXPORTTOEXCEL, get_ExportToExcel);
  yield takeLatest(GET_ORDER_ADITIONALINFO, get_OrderAditionalInfo);
  yield takeLatest(GET_FUllFillMENT, get_FulFillMent);
  yield takeLatest(SET_PRINTPACKSLIP, get_PrintPackingSlip);
  yield takeLatest(
    SET_TRACK_DATA_RECEIVED_FROM_SAVE,
    set_trackDataReceivedFromSave
  );
  yield takeLatest(GET_CARD, get_Card);
  yield takeLatest(GET_UNLOCK_ORDER, get_UnlockOrder);
  yield takeLatest(GET_FILEATTACHED, get_FileAttached);
  yield takeLatest(SET_FILEATTACHED, set_FileAttached);
  yield takeLatest(DOWNLOAD_FILEATTACHED, downloadFile);
  yield takeLatest(DELETE_FILEATTACHED, deleteFile);
  yield takeLatest(GET_COMMENTS, fetch_Comments);
  yield takeLatest(SET_POSTCOMMENT, post_Comment);
  yield takeLatest(DELETE_COMMENT, delete_Comment);
  yield takeLatest(EDIT_COMMENT, edit_Comment);
  yield takeLatest(GET_ORDERS, get_Orders);
  yield takeLatest(GET_COMMENT_ACTION, get_CommentAction);
  //   yield takeLatest(GET_COMMENT_ACTION, get_CommentAction);
  yield takeLatest(GET_ORDER_ACTIVATIONINFO, get_ActivationInfo);
  yield takeLatest(SUBMIT_DEVALUATION, submit_Devaluation);
  yield takeLatest(CHECK_STATUS, check_Status);
  yield takeLatest(DOWNLOAD_DEVALUATION_EXCEL, download_Devaluation_Excel);
  yield takeLatest(SET_PRINT_INVOICE, get_PrintInvoice);

  yield takeLatest(GET_ORDER_DATA, get_Order_Data);
  yield takeLatest(GET_PRINT_SLIP, get_PrintSlip);
  yield takeLatest(GET_INVOICE, get_Invoice);
  yield takeLatest(SEND_EMAIL, send_Email);
  // yield takeLatest(GET_REFRESH_TOKEN, handleTokenRefresh);
}
// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([Watcher()]);
}
